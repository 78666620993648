import CryptoJS from "./crypto-js"
import { md5 } from "js-md5"

// JSON
if (typeof String.prototype.endsWith != 'function') {
  String.prototype.endsWith = function (str) {
    return this.slice(-str.length) == str
  }
}
function evil(fn) {
  var Fn = Function // 一个变量指向Function，防止有些前端编译工具报错
  return new Fn('return ' + fn)()
}
function EDMifResult(re) {
  var result = re
  if (result !== '[') {
    result = result.slice(0, -1)
  }
  return result
}
function isUploadJsonObj(uploadJsonObj, typeofObj) {
  var result = ''
  switch (typeofObj) {
    case '[object Array]':
      result += '['
      for (var i = 0, len = uploadJsonObj.length; i < len; i++) {
        var curVal = JSON.stringify(uploadJsonObj[i])
        result += (curVal === undefined ? null : curVal) + ','
      }
      result = EDMifResult(result)
      result += ']'
      return result
    case '[object Date]':
      return '"' + (uploadJsonObj.toJSON ? uploadJsonObj.toJSON() : uploadJsonObj.toString()) + '"'
    case '[object RegExp]':
      return '{}'
    case '[object Object]':
      result += '{'
      for (i in uploadJsonObj) {
        if (Object.prototype.hasOwnProperty.call(uploadJsonObj, 'bar')) {
          var curVald = JSON.stringify(uploadJsonObj[i])
          if (curVald !== undefined) {
            result += '"' + i + '":' + curVald + ','
          }
        }
      }
      result = EDMifResult(result)
      result += '}'
      return result
    case '[object String]':
      return '"' + uploadJsonObj.toString() + '"'
    case '[object Boolean]' || '[object Number]':
      return uploadJsonObj.toString()
    default:
      break
  }
}
if (window.JSON === undefined) {
  window.JSON = {
    parse: function (json) {
      return evil('(' + json + ')')
    },
    stringify: function (uploadJsonObj) {
      var curVal
      if (uploadJsonObj === null) {
        return String(uploadJsonObj)
      }
      switch (typeof uploadJsonObj) {
        case 'boolean' || 'number':
          return String(uploadJsonObj)
        case 'string':
          return '"' + uploadJsonObj + '"'
        case 'function' || 'undefined':
          return undefined
        default:
          break
      }
      return isUploadJsonObj(uploadJsonObj, Object.prototype.toString.call(uploadJsonObj))
    }
  }
}
// String
if (!String.prototype.trim) {
  String.prototype.trim = function () {
    return this.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '')
  }
}

var edmUploadInstanceGuidval = new Date().getTime()

/**
 * 错误信息集
 */
var EDMUploadErrorCodes = {
  UPLOAD_ERROR: {
    status: 500,
    message: 'An exception occurred when initializing the document!'
  },
  LOAD_TOKEN_ERROR: {
    status: 500,
    message: 'Access timed out. Please log in again or contact IT personnel.'
  },
  EMPTY_OPTIONS: {
    status: 90001,
    message: 'EDM upload plugin EdmUpObj[ID] is empty!'
  },
  EMPTY_APP_ID: { status: 90002, message: 'The appId is not set!' },
  REPEATED_UPLOAD: {
    status: 90003,
    message: 'Currently, a single document is uploaded!'
  },
  EXCEEDS_LIMIT: {
    status: 90004,
    message: 'The number of files exceeds the limit of '
  },
  EXECUTION_INSTANCE: { status: 90005, message: 'This instance is executing!' },
  MAX_SIZE: { status: 90006, message: 'The file size exceeds the limit of ' },
  PARAL_MAZ_SIZE: { status: 90006, message: 'The file "docName" size exceeds the limit of ' },
  FILE_ALLOWED: { status: 90007, message: 'The document type is not allowed!' },
  TOKEN_INVALID: { status: 90008, message: 'The token is invalid!' },
  NO_PERMISSION: { status: 90009, message: 'No operation permission!' },
  RELACE_BROWSER: {
    status: 90010,
    message:
      'The file size you uploaded exceeds 10M ！' +
      'Please upload  the file through Google Chrome browser or Microsoft Edge browser.'
  },
  NET_EXCEPTION: {
    status: 404,
    message: 'The network is exception. Check the network environment.'
  },
  UNKNOWN_ERROR: {
    status: 407,
    message: 'Unknown error！Upload failed.'
  },
  FILENAME_IS_NOTIN_WHITELIST: {
    status: 11017,
    message: 'The format (.suffix) is not allowed'
  },
  PARAL_FILENAME_IS_NOTIN_WHITELIST: {
    status: 11017,
    message: 'The file "docName" format (.suffix) is not allowed'
  },
  MIN_SIZE: {
    status: 90006,
    message: 'The file size is lower than the limit of '
  },
  PARAL_MIN_SIZE: {
    status: 90006,
    message: 'The file "docName" size is lower than the limit of '
  },
  NO_SUFFIX_FILE: {
    status: 90007,
    message: 'Files without suffixes are not supported currently.'
  },
  SPECIAL_CHARACTERS_EXIST: {
    status: 11005,
    message: 'The file name contains special characters,please rename the file and upload again'
  },
  FILE_UPLOADING: {
    status: 11006,
    message: 'The {name} is being uploaded. You do not need to upload it again.'
  },
  ZERO_SIZE_FILE: {
    status: 110231,
    message: 'The file with size 0 is not allowed.'
  }
}

// 中文错误信息集
var EDMUploadErrorCodesZhCN = {
  UPLOAD_ERROR: {
    status: 500,
    message: '初始化文档时发生异常！'
  },
  LOAD_TOKEN_ERROR: {
    status: 500,
    message: '访问超时，请重新登录或联系IT人员;'
  },
  EMPTY_OPTIONS: {
    status: 90001,
    message: 'EDM上传插件EdmUpObj[ID]为空！'
  },
  EMPTY_APP_ID: { status: 90002, message: 'appId未设置！' },
  REPEATED_UPLOAD: {
    status: 90003,
    message: '现在有一个文档正在上传！'
  },
  EXCEEDS_LIMIT: {
    status: 90004,
    message: '批量上传个数超过限制'
  },
  EXECUTION_INSTANCE: { status: 90005, message: '此实例正在执行！' },
  MAX_SIZE: { status: 90006, message: '文件大小超过限制' },
  PARAL_MAZ_SIZE: { status: 90006, message: '文件"docName"大小超过限制' },
  FILE_ALLOWED: { status: 90007, message: '该文件类型不支持！' },
  TOKEN_INVALID: { status: 90008, message: 'token无效！' },
  NO_PERMISSION: { status: 90009, message: '无操作权限！' },
  RELACE_BROWSER: {
    status: 90010,
    message:
      '您上传的文件大小超过10M !请通过Google Chrome浏览器或Microsoft Edge浏览器上传文件。'
  },
  NET_EXCEPTION: {
    status: 404,
    message: '网络异常。检查网络环境。'
  },
  UNKNOWN_ERROR: {
    status: 407,
    message: '未知错误！上传失败。'
  },
  FILENAME_IS_NOTIN_WHITELIST: {
    status: 11017,
    message: '格式(.suffix)暂不支持'
  },
  PARAL_FILENAME_IS_NOTIN_WHITELIST: {
    status: 11017,
    message: '文件"docName"格式(.suffix)暂不支持'
  },
  MIN_SIZE: {
    status: 90006,
    message: '文件大小低于限制'
  },
  PARAL_MIN_SIZE: {
    status: 90006,
    message: '文件"docName"大小低于限制'
  },
  NO_SUFFIX_FILE: {
    status: 90007,
    message: '暂不支持无后缀文件'
  },
  SPECIAL_CHARACTERS_EXIST: {
    status: 11005,
    message: '文件名包含特殊字符，请重命名后上传'
  },
  FILE_UPLOADING: {
    status: 11006,
    message: '文件{name}正在上传中，无需重复上传'
  },
  ZERO_SIZE_FILE: {
    status: 110231,
    message: '暂不支持大小为0的文件'
  }
}

function setErrorCodesLanguage(ID, lang) {
  // 先对lang进行处理
  if (typeof lang !== 'string') {
    lang = navigator.language.indexOf('zh') > -1 ? 'zh_CN' : 'en_US'
  } else if (lang.startsWith('zh_') || lang === 'zh' || lang.startsWith('zh-')) {
    lang = 'zh_CN'
  } else if (lang.startsWith('en_') || lang === 'en' || lang.startsWith('en-')) {
    lang = 'en_US'
  } else {
    lang = navigator.language.indexOf('zh') > -1 ? 'zh_CN' : 'en_US'
  }
  EdmUpObj[ID].edmLanguage = lang

  switch (EdmUpObj[ID].edmLanguage) {
    case 'zh_CN':
      EdmUpObj[ID].uploadErrorCodes = EDMUploadErrorCodesZhCN
      break;
    case 'en_US':
      EdmUpObj[ID].uploadErrorCodes = EDMUploadErrorCodes
      break;
    default:
      EdmUpObj[ID].uploadErrorCodes = EDMUploadErrorCodes
      break;
  }
}

// 用来换算文件大小单位
function convertUnit(bytes) {
  if (!bytes) {
    return ''
  }
  if (bytes >= 1e9) {
    const result = bytes / (1024 * 1024 * 1024);
    return result % 1 === 0 ? result.toFixed(0) + " GB" : result.toFixed(2) + " GB";
  } else if (bytes >= 1e6) {
    const result = bytes / (1024 * 1024);
    return result % 1 === 0 ? result.toFixed(0) + " MB" : result.toFixed(2) + " MB";
  } else if (bytes >= 1e3) {
    const result = bytes / 1024;
    return result % 1 === 0 ? result.toFixed(0) + " KB" : result.toFixed(2) + " KB";
  } else {
    return bytes + " B";
  }
}

// 自定义报错
var customizedError = function (status, message) {
  return { status: status, message: message }
}
/**
 * 获取当前用户网页的协议
 */
var getUploadProtocol = function () {
  if (location.protocol === 'https:' || location.protocol === 'http:') {
    return location.protocol
  }
  return 'https:'
}

//  实例对象 upload Instance Object
var EdmUpObj = {}

//  EDM公共方法
var EdmUpUtils = {
  /**
   * 监听页面的刷新事件
   */
  pageRefreshListen(ID) {
    window.addEventListener('beforeunload', function (e) {
      if (EdmUpObj[ID].uploadingFile) {
        const uploadingKey = `${EdmUpObj[ID].uploadingFile.name}#${EdmUpObj[ID].uploadingFile.size}#${EdmUpObj[ID].uploadingFile.lastModified}#uploading`
        if (localStorage.getItem(uploadingKey)) {
          localStorage.removeItem(uploadingKey)
        }
      }
    })
  },

  setDefaultLanguage(ID) {
    // 提示语初始语言英文，错误集为英文错误集
    if (navigator.language.indexOf('zh') > -1) {
      EdmUpObj[ID].edmLanguage = 'zh_CN'
      EdmUpObj[ID].uploadErrorCodes = EDMUploadErrorCodesZhCN
    } else {
      EdmUpObj[ID].edmLanguage = 'en_US'
      EdmUpObj[ID].uploadErrorCodes = EDMUploadErrorCodes
    }
  },

  getOnLine: function () {
    try {
      const xhrUp = new XMLHttpRequest()
      xhrUp.open('get', `${location.href}`, false)
      xhrUp.send()
      if (xhrUp.status === 200) {
        return true
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  },
  // 获取文件切块数量
  getChunkAtom: function (atom) {
    if (atom) {
      // 默认超过20M的文档则以默认8M为一个分片大小进行分片处理；
      EdmUpUtils.CHUNK_ATOM = atom
      if (EdmUpUtils.CHUNK_ATOM - 2 < 0) {
        EdmUpUtils.CHUNK_ATOM = 2
      } else if (4 > EdmUpUtils.CHUNK_ATOM && EdmUpUtils.CHUNK_ATOM >= 2) {
        EdmUpUtils.CHUNK_ATOM = 2
      } else if (8 > EdmUpUtils.CHUNK_ATOM && EdmUpUtils.CHUNK_ATOM >= 4) {
        EdmUpUtils.CHUNK_ATOM = 4
      } else if (16 > EdmUpUtils.CHUNK_ATOM && EdmUpUtils.CHUNK_ATOM >= 8) {
        EdmUpUtils.CHUNK_ATOM = 8
      } else if (32 > EdmUpUtils.CHUNK_ATOM && EdmUpUtils.CHUNK_ATOM >= 16) {
        EdmUpUtils.CHUNK_ATOM = 16
      } else if (64 > EdmUpUtils.CHUNK_ATOM && EdmUpUtils.CHUNK_ATOM >= 32) {
        EdmUpUtils.CHUNK_ATOM = 32
      } else if (EdmUpUtils.CHUNK_ATOM - 64 > 0) {
        EdmUpUtils.CHUNK_ATOM = 64
      }
    }
  },
  filesInit(ID, files) {
    EdmUpObj[ID].edmtokenInterval = window.setInterval(function () {
      EdmUpObj[ID].requestEdmToken(true)
    }, 240000)
    // 重置进度
    EdmUpObj[ID].done = 0
    EdmUpObj[ID].cacheDocuments = {}
    EdmUpObj[ID].fileCount = files.length
    for (var i = 0; i < files.length; i++) {
      // 每个文档都绑定一个唯一标识（临时的），标识在前端生成
      var s = EdmUpUtils.guid()
      EdmUpObj[ID].cacheDocumentKeys.push(s)
      files[i].cacheSign = s
      EdmUpObj[ID].cacheDocuments[s] = files[i]
      EdmUpObj[ID].cacheDocuments[s].status = {
        statusCode: 101,
        message: 'Preparing to upload.准备上传中'
      }
      EdmUpObj[ID].cacheDocuments[s].progress = 0
      EdmUpObj[ID].cacheDocuments[s].index = i
    }
    if (files.length > 1) {
      EdmUpObj[ID].updateFile = false
    }
    EdmUpObj[ID].notice({
      type: 'init',
      data: files
    })
    EdmUpObj[ID].uploadStatus(EdmUpObj[ID].cacheDocuments)
  },
  parallelVerficate: function (ID, file) {
    if (EdmUpUtils.isIE && file.size > 10 * 1024 * 1024) {
      EdmUpObj[ID].failMessage.push(EdmUpObj[ID].uploadErrorCodes.RELACE_BROWSER)
    }
    const docName = file.name
    const whitelist = EdmUpObj[ID].whitelist + ','
    let isNoSuffixFile = false
    let suf = ''
    if (docName.indexOf('.') < 0) {
      isNoSuffixFile = true
      if (EdmUpObj[ID].whitelist !== '' && whitelist.toLowerCase().indexOf('.null') === -1 && whitelist.toLowerCase().indexOf('.*') === -1) {
        EdmUpObj[ID].failMessage.push([0, docName])
        return false
      }
    } else {
      suf = docName.substring(docName.lastIndexOf('.') + 1, docName.length).toLowerCase()
    }
    if (!isNoSuffixFile && EdmUpObj[ID].whitelist !== '' && (whitelist.toLowerCase().indexOf('.' + suf + ',') === -1 && whitelist.toLowerCase().indexOf('.*') === -1)) {
      EdmUpObj[ID].failMessage.push([0, docName])
      return false
    }
    if (EdmUpObj[ID].fileSizeRange) {
      if (file.size > EdmUpObj[ID].fileSizeRange[1]) {
        EdmUpObj[ID].failMessage.push([1, docName])
        return false
      } else if (file.size < EdmUpObj[ID].fileSizeRange[0]) {
        EdmUpObj[ID].failMessage.push([2, docName])
        return false
      }
    } else if (EdmUpObj[ID].tenantUploadConfig.singleFileMaxSize) {
      if (file.size > EdmUpObj[ID].tenantUploadConfig.singleFileMaxSize) {
        EdmUpObj[ID].failMessage.push([1, docName])
        return false
      }
    }
    return true
  },
  fileVerification: function (ID, file, isInit) {
    if (EdmUpUtils.isIE && file.size > 10 * 1024 * 1024) {
      EdmUpObj[ID].failFunction(EdmUpObj[ID].uploadErrorCodes.RELACE_BROWSER)
      return false
    }
    const docName = file.name
    if (!!isInit) {
      const failWhitelistSuffix = EdmUpUtils.checkWhitelist(ID)
      if (failWhitelistSuffix.indexOf('.null') > -1) {
        EdmUpObj[ID].failFunction(EdmUpObj[ID].uploadErrorCodes.NO_SUFFIX_FILE)
        return false
      }
      if (!!failWhitelistSuffix) {
        // 后缀中英文提示语
        const message = EdmUpObj[ID].uploadErrorCodes
          .FILENAME_IS_NOTIN_WHITELIST
          .message.replace('.suffix', failWhitelistSuffix)
        const CustomizedErrorCodes = customizedError(EdmUpObj[ID].uploadErrorCodes.FILENAME_IS_NOTIN_WHITELIST.status, message)
        EdmUpObj[ID].failFunction(CustomizedErrorCodes)
        return false
      }
    } else {
      const newWhite = EdmUpObj[ID].whitelist + ','
      let noSuffixFileFlag = true
      let suf = ''
      if (docName.indexOf('.') > -1) {
        noSuffixFileFlag = false
        suf = docName.substring(docName.lastIndexOf('.') + 1, docName.length).toLowerCase()
      } else {
        if (EdmUpObj[ID].whitelist !== '' && newWhite.toLowerCase().indexOf('.null') === -1 && newWhite.toLowerCase().indexOf('.*') === -1) {
          EdmUpObj[ID].failFunction(EdmUpObj[ID].uploadErrorCodes.NO_SUFFIX_FILE)
          return false
        }
      }
      let isWhitelist = newWhite.toLowerCase().indexOf('.' + suf + ',') === -1 ? false : true
      if (!isWhitelist && newWhite.toLowerCase().indexOf('.*') > -1) {
        isWhitelist = true
      }
      if (!noSuffixFileFlag && !isWhitelist && EdmUpObj[ID].whitelist !== '') {
        const suffixIndex = docName.lastIndexOf('.')
        const suffix = docName.slice(suffixIndex)
        // 后缀中英文提示语
        const message = EdmUpObj[ID].uploadErrorCodes
          .FILENAME_IS_NOTIN_WHITELIST
          .message.replace('.suffix', suffix)
        const CustomizedErrorCodes = customizedError(EdmUpObj[ID].uploadErrorCodes.FILENAME_IS_NOTIN_WHITELIST.status, message)
        EdmUpObj[ID].failFunction(CustomizedErrorCodes)
        return false
      }
    }
    if (docName.length > 255) {
      EdmUpObj[ID].failFunction({
        status: 90011,
        message: 'The file name ' + docname + ' exceeds 255 characters. Please change the file name.'
      })
      return false
    }
    return true
  },
  // 判断实例参数是否为空
  isEmptyOp: function (ID) {
    if (EdmUpUtils.isEmpty(EdmUpObj[ID])) {
      EdmUpObj[ID].unavailableInstance = true
      EdmUpObj[ID] = {}
      return true
    } else {
      EdmUpObj[ID].unavailableInstance = false
      return false
    }
  },
  // 判断传入的变量是否为空
  isEmpty: function (o) {
    if (o === null || o === undefined) {
      return true
    }
    var type = typeof o
    if (type === 'string' && o.trim().length < 1) {
      return true
    }
    if (type === 'object' && o.length !== undefined) {
      return o.length < 1
    }
    if (type === 'object' && o.length === undefined) {
      return JSON.stringify(o) === '{}'
    }
    return false
  },

  /**
   * 判断值的类型是否为数值
   * @param {*} value 值
   * @returns {boolean} 是否为数值
   */ 
  isNumeric(value) {
    return !isNaN(parseFloat(value)) && isFinite(value)
  },
  guid: function () {
    edmUploadInstanceGuidval = Number(edmUploadInstanceGuidval) + 1
    return 'edm' + edmUploadInstanceGuidval
  },
  // 创建 XMLHTTPRequest 对象
  newXMLHttpRequest: function (uploadCredentials) {
    uploadCredentials = typeof uploadCredentials === 'boolean' ? uploadCredentials : true
    var uploadXmlHttp
    // 使用其他浏览器创建XMLHttpRequest对象
    if (typeof XMLHttpRequest !== 'undefined') {
      try {
        uploadXmlHttp = new XMLHttpRequest()
        uploadXmlHttp.withCredentials = !!uploadCredentials
        return uploadXmlHttp
      } catch (e) {
        uploadXmlHttp = false
      }
    }
    if (!uploadXmlHttp) {
      try {
        uploadXmlHttp = new ActiveXObject('Msxml2.XMLHTTP')
      } catch (e) {
        // 使用旧版本的IE创建XMLHttpRequest对象
        try {
          uploadXmlHttp = new ActiveXObject('Microsoft.XMLHTTP')
        } catch (E) {}
      }
    }
    if ('withCredentials' in uploadXmlHttp) {
      uploadXmlHttp.withCredentials = !!uploadCredentials
    } else if (uploadCredentials) {
      uploadXmlHttp = new XDomainRequest()
    }
    return uploadXmlHttp
  },
  // url 拼接
  uriParamFormat: function (u, p) {
    if (u.indexOf('?') < 0) {
      u = u + '?'
    } else if (!u.endsWith('&')) {
      u = u + '&'
    }
    if (typeof p === 'object' && JSON.stringify(p) !== '{}') {
      for (var key in p) {
        u = u + key + '=' + p[key] + '&'
      }
      u = u.substring(0, u.length - 1)
    }
    return u
  },
  // 把 url 转为 对象
  parseUrlQuery: function (uploadUrl) {
    var queryObj = {}
    var reg = /[?&]([^=&#]+)=([^&#]*)/g
    var uploadQuerys = uploadUrl.match(reg)
    if (uploadQuerys) {
      for (var i = 0; i < uploadQuerys.length; i++) {
        let firstEqualSign = uploadQuerys[i].indexOf('=')
        let key = uploadQuerys[i].substring(1, firstEqualSign)
        var value = uploadQuerys[i].substring(firstEqualSign + 1)
        queryObj[key] ? (queryObj[key] = [].concat(queryObj[key], value)) : (queryObj[key] = value)
      }
    }
    return queryObj
  },
  envs: {
    cloudblue: {
      beta: 'http://hicuat.huawei.com/edm',
      prod: 'http://apig.heds.hihonor.com/api/edm'
    },
    internal: {
      beta: `${getUploadProtocol()}//edm3-beta.huawei.com/edm`,
      prod: `${getUploadProtocol()}//edm3.huawei.com/edm`
    },
    apigw: {
      beta: 'http://edm3-dmz-beta.his.huawei.com/edm',
      prod: 'http://edm3-dmz.his.huawei.com/'
    }
  },
  isIE: !!window.ActiveXObject || 'ActiveXObject' in window,
  EMPTY: '',
  CHUNK_LIMIT: 20, // 定义多少M开始分片
  CHUNK_ATOM: 8, // 分片的每片大小
  CHUNK_START_ZERO: false, // 分片序号是否从0开始，false时从1开始
  CHUNK_BATCH_LIMIT: 5, // 每个批次的并发数量
  REPLAY_LIMIT: 3, // 分片上传中若有部分片未一次上传成功，有50次重试机会
  TOKEN_REPLAY_LIMIT: 50,
  HEADERS: {
    APP_ID: 'x-app-id',
    TRACE_ID: 'x-tracerid',
    TOKEN: 'EDM-Authorization'
  },
  NOTICE: {
    INIT: 'init', // 初始化
    PROGRESS: 'progress' // 进度条更新 --args --disable-web-security --user-data-dir=C:\MyChromeDevUserData
  },
  TOKEN_PAMETERS: {
    action: 'edmTest',
    serverType: 'upload'
  },
  // 获取随机值
  getUpRandomValue: function () {
    const RanArray = new Uint32Array(1)
    let crypto = window.crypto || window.msCrypto
    crypto.getRandomValues(RanArray)
    const randomStr = RanArray[0]
    return 'ID' + randomStr
  },
  // 判断 APPId 是否为空
  isEmptyAppId: function (ID) {
    if (EdmUpUtils.isEmpty(EdmUpObj[ID].appId)) {
      EdmUpObj[ID].unavailableInstance = true
      EdmUpObj[ID].failFunction(EdmUpObj[ID].uploadErrorCodes.EMPTY_APP_ID)
      return true
    } else {
      return false
    }
  },
  // 设置上传接口
  defaultNetwork: function (ID) {
    EdmUpObj[ID].contextPath = {
      small: '/projects/{appId}/documents',
      chunkInit: '/projects/{appId}/chunk/documents?type=init',
      chunkUpload: '/projects/{appId}/chunk/documents?type=upload',
      chunkMerge: '/projects/{appId}/chunk/documents?type=merge',
      token: '/projects/{appId}/tokens'
    }
    EdmUpObj[ID].network = EdmUpObj[ID].network || 'internal'
    if (EdmUpUtils.isIE && EdmUpObj[ID].isCheckCode === 'Y') {
      EdmUpUtils.CHUNK_ATOM = 0.1
    }
  },
  /**
   * 移除正在上传文件标识
   * @param {Number} ID 
   */
  removeUploadingFlag: function (ID, type) {
    let uploadingKey = ''
    if (EdmUpObj[ID].uploadingFile) {
      uploadingKey = `${EdmUpObj[ID].uploadingFile.name}#${EdmUpObj[ID].uploadingFile.size}#${EdmUpObj[ID].uploadingFile.lastModified}#uploading`
    }
    if (localStorage.getItem(uploadingKey) && type !== 'uploading') {
      localStorage.removeItem(uploadingKey)
    }
  },
  // 失败回调
  setFail: function (ID) {
    if (EdmUpUtils.isEmpty(EdmUpObj[ID].fail)) {
      EdmUpObj[ID].failFunction = function (data, type) {
        EdmUpUtils.removeUploadingFlag(ID, type)
        if (data.status && data.status !== 90005) {
          EdmUpObj[ID].uploading = false
        }
      }
    } else {
      EdmUpObj[ID].failFunction = function (data, type) {
        EdmUpUtils.removeUploadingFlag(ID, type)
        if (data.status && data.status !== 90005) {
          EdmUpObj[ID].uploading = false
        }
        EdmUpObj[ID].fail(data)
      }
    }
  },
  setComputeProgress: function (ID) {
    EdmUpObj[ID].ComputeProgress = EdmUpObj[ID].ComputeProgress
    if (
      EdmUpObj[ID].ComputeProgress === null ||
      EdmUpObj[ID].ComputeProgress === undefined ||
      typeof EdmUpObj[ID].ComputeProgress !== 'function'
    ) {
      EdmUpObj[ID].ComputeProgress = function (data) {
        return data * 1
      }
    }
  },
  setNotice: function (ID) {
    EdmUpObj[ID].notice = EdmUpObj[ID].notice
    if (
      EdmUpObj[ID].notice === null ||
      EdmUpObj[ID].notice === undefined ||
      typeof EdmUpObj[ID].notice !== 'function'
    ) {
      EdmUpObj[ID].notice = function (data) {
        return data
      }
    }
  },
  // 网速回调
  setNetworkSpeed: function (ID) {
    EdmUpObj[ID].networkSpeed = EdmUpObj[ID].networkSpeed
    if (
      EdmUpObj[ID].networkSpeed === null ||
      EdmUpObj[ID].networkSpeed === undefined ||
      typeof EdmUpObj[ID].networkSpeed !== 'function'
    ) {
      EdmUpObj[ID].networkSpeed = function (data) {
        return data
      }
    }
  },
  // 设置上传状态回调
  setStatusCallback: function (ID) {
    // 状态通知
    EdmUpObj[ID].uploadStatus = EdmUpObj[ID].status
    if (
      EdmUpObj[ID].uploadStatus === null ||
      EdmUpObj[ID].uploadStatus === undefined ||
      typeof EdmUpObj[ID].uploadStatus !== 'function'
    ) {
      EdmUpObj[ID].uploadStatus = function (data) {
        return data
      }
    }
  },
  // 上传进度通知回调
  setHandleProgress: function (ID) {
    // 进度通知
    EdmUpObj[ID].uploadHandleProgress = EdmUpObj[ID].progress
    if (
      EdmUpObj[ID].uploadHandleProgress === null ||
      EdmUpObj[ID].uploadHandleProgress === undefined ||
      typeof EdmUpObj[ID].uploadHandleProgress !== 'function'
    ) {
      EdmUpObj[ID].uploadHandleProgress = function (data) {
        return data
      }
    }
  },
  // 文件上传初始化
  initConfiguration: function (ID) {
    // 访问凭证
    EdmUpObj[ID].enterpriseDocumentToken = ''
    // 访问链路
    EdmUpObj[ID].requestTrace = ''
    // 租户上传场景的配置信息
    EdmUpObj[ID].tenantUploadConfig = {}
    // 创建的上传标签元素，若调用directUpload直接传文件使用，则不会创建
    EdmUpObj[ID].tempInputFileDocument = null
    // 是否正在上传
    EdmUpObj[ID].uploading = false
    // 批量上传监听属性
    EdmUpObj[ID].batchQueueListen = {}
    // 批量上传队列
    EdmUpObj[ID].batchQueue = {}
    // 缓存上传文档信息{ 唯一标识， 进度，基本信息 }
    EdmUpObj[ID].cacheDocuments = {}
    // 用于重试控制
    EdmUpObj[ID].replayAtoms = {}
    // 当前动作完成上传数量
    EdmUpObj[ID].done = 0
    // 当前动作文件数量
    EdmUpObj[ID].fileCount = 0
    // 标识当前动作是否停止
    EdmUpObj[ID].uploadIndex = 0
    // 当前上传文件的序列
    EdmUpObj[ID].cacheDocumentKeys = []
    EdmUpObj[ID].isStop = false
    // token 重试次数
    EdmUpObj[ID].tokenReplayCount = 0
    // 并行上传时错误信息收集
    EdmUpObj[ID].failMessage = []
    // 批量上传的文件信息
    EdmUpObj[ID].files = []
    // 并行上传通过校验的文件信息
    EdmUpObj[ID].curDocuments = []
    // 接口 重试次数
    EdmUpObj[ID].ReplayCount = 0
    if (EdmUpObj[ID].docId) {
      EdmUpObj[ID].updateFile = true
    }
    // 网速状态列表
    EdmUpUtils.NetworkSpeedObject = {}
  },
  setServiceDomain: function (ID) {
    // edm服务域名
    EdmUpObj[ID].edmServiceDomain = EdmUpObj[ID].endPoint
    if (EdmUpUtils.isEmpty(EdmUpObj[ID].edmServiceDomain)) {
      if (
        EdmUpUtils.isEmpty(EdmUpObj[ID].env) ||
        EdmUpUtils.isEmpty(EdmUpUtils.envs[EdmUpObj[ID].network][EdmUpObj[ID].env])
      ) {
        EdmUpObj[ID].edmServiceDomain = EdmUpUtils.envs[EdmUpObj[ID].network].beta
      } else {
        EdmUpObj[ID].edmServiceDomain = EdmUpUtils.envs[EdmUpObj[ID].network][EdmUpObj[ID].env]
      }
    }
    EdmUpObj[ID].tokenMethod = EdmUpObj[ID].tokenMethod || 'GET'
    EdmUpObj[ID].edmtokenInterval = null
    // 定时器
    EdmUpObj[ID].allowedFolder = !!EdmUpObj[ID].allowedFolder
    EdmUpObj[ID].https = !!EdmUpObj[ID].https // 是否启用https
    // 用户使用上传的白名单，会覆盖console配置的应用上传白名单
    EdmUpUtils.getChunkAtom(EdmUpObj[ID].chunkAtom)
    EdmUpObj[ID].whitelist = EdmUpObj[ID].whitelist ? EdmUpObj[ID].whitelist.toLocaleLowerCase() : ''
    EdmUpObj[ID].fileSizeRange = EdmUpObj[ID].fileSizeRange || null // 上传文件大小范围
    EdmUpObj[ID].isCheckCode = EdmUpObj[ID].isCheckCode // 文档校验isCheckCode
    EdmUpObj[ID].encryptScope = EdmUpObj[ID].encryptScope // 文档加解密配置
    EdmUpObj[ID].encryptType = EdmUpObj[ID].encryptType // 文档加解密类型
    EdmUpObj[ID].docId = EdmUpObj[ID].docId
    EdmUpObj[ID].apigw = EdmUpObj[ID].apigw || null // APIGW网关鉴权
    EdmUpObj[ID].sdkDomainHeader = EdmUpObj[ID].sdkDomainHeader || null // 获取EDMtoken请求头
    EdmUpObj[ID].appId = EdmUpObj[ID].appId // 应用ID
    EdmUpObj[ID].breakWhenError = !!EdmUpObj[ID].breakWhenError // 遇到错误终止动作
    EdmUpObj[ID].single = !!EdmUpObj[ID].single // 是否限制单文档
    for (var contextKey in EdmUpObj[ID].contextPath) {
      EdmUpObj[ID].contextPath[contextKey] = EdmUpObj[ID].contextPath[contextKey].replace('{appId}', EdmUpObj[ID].appId)
    }
    if (EdmUpObj[ID].https) {
      EdmUpObj[ID].edmServiceDomain = EdmUpObj[ID].edmServiceDomain.replace('http://', 'https://')
    }
    EdmUpObj[ID].tokenDomain = EdmUpObj[ID].sdkDomain || EdmUpObj[ID].edmServiceDomain + EdmUpObj[ID].contextPath.token
  },
  setAsyncFunction: function (ID) {
    EdmUpObj[ID].asyncFunction = function (fun) {
      setTimeout(fun, 1)
    }
  },
  // 设置 EDMToken 参数
  setRequestEdmTokenParams: function (ID) {
    var params = {
      appId: EdmUpObj[ID].appId,
      serverType: EdmUpUtils.TOKEN_PAMETERS.serverType,
      fileWhiteList: EdmUpObj[ID].whitelist || '',
      _t: new Date().getTime(),
      paraSah256: 'edm'
    }
    if (EdmUpObj[ID].userPermissionsExt) {
      params.userPermissionsExt = JSON.stringify(EdmUpObj[ID].userPermissionsExt)
    }

    let sdkDomainParame = EdmUpObj[ID].sdkDomainParame || {}
    if (Object.prototype.toString(sdkDomainParame) !== '[object Object]') {
      sdkDomainParame = {}
    }
    for (let item in sdkDomainParame) {
      if (!params[item]) {
        params[item] = sdkDomainParame[item]
      }
    }
    return params
  },
  // 过滤 url 中重复字段
  uploadFilterDupFields: function (Url) {
    let paramsObj = EdmUpUtils.parseUrlQuery(Url)
    let uploadUniqueParams = {}
    for (let item in paramsObj) {
      if (!uploadUniqueParams[item]) {
        if (typeof paramsObj[item] === 'object' && paramsObj[item].length > 1) {
          uploadUniqueParams[item] = paramsObj[item][0]
        } else {
          uploadUniqueParams[item] = paramsObj[item]
        }
      }
    }
    Url = Url.indexOf('?') !== -1 ? Url.substring(0, Url.indexOf('?')) : Url
    return EdmUpUtils.uriParamFormat(Url, uploadUniqueParams)
  },
  // 判断 EDMToken 的请求方式是否为 GET 请求，如果为 GET 请求就把参数拼接到 URL 后面
  serRequestEdmTokenIfGEt: function (ID, u, params) {
    var url = u
    if (EdmUpObj[ID].tokenMethod === 'GET') {
      url = EdmUpUtils.uriParamFormat(url, params)
    }
    return EdmUpUtils.uploadFilterDupFields(url)
  },
  // 把对象转换为数组
  getValueArr: function (valueObj) {
    var uploadObj = Object.keys(valueObj)
    var uploadArr = Object.getOwnPropertyNames(uploadObj)
    var uploadArr2 = Object.keys(uploadObj)
    var uploadValArr = uploadArr.map(function (i) {
      return uploadObj[i]
    })
    var uploadValArr2 = uploadArr2.map(function (i) {
      return uploadObj[i]
    })
    var uploadValArr22 = Object.keys(uploadObj).map(function (i) {
      return uploadObj[i]
    })
    return uploadValArr22
  },
  // 设置请求体
  setQueryBody: function (ID, url) {
    var queryBody = EdmUpUtils.parseUrlQuery(url)
    queryBody.appId = EdmUpObj[ID].appId
    queryBody.serverType = EdmUpUtils.TOKEN_PAMETERS.serverType
    queryBody.fileWhiteList = EdmUpObj[ID].whitelist || ''
    queryBody.userPermissionsExt = EdmUpObj[ID].userPermissionsExt
    // 自定义参数
    let sdkDomainParame = EdmUpObj[ID].sdkDomainParame || {}
    if (Object.prototype.toString(sdkDomainParame) !== '[object Object]') {
      sdkDomainParame = {}
    }
    for (let item in sdkDomainParame) {
      if (!queryBody[item]) {
        queryBody[item] = sdkDomainParame[item]
      }
    }
    return queryBody
  },
  // 白名单校验，优先使用token里的fileWhiteList
  // 虽然后端做了交集，但为了兼容以前用户，需要前端也做交集
  // 如果前端把白名单传给过后端，则configWhiteList是已经做过交集的白名单
  // 否则configWhiteList是后端默认的白名单
  getWhiteList: function (configWhiteList, ID) {
    // 前端做白名单交集
    const configWhiteArry = configWhiteList.replace(/\s+/g, '').split(',')
    const definedWhiteArry = EdmUpObj[ID].whitelist.replace(/\s+/g, '').split(',')
    var newWhiteList = configWhiteArry.filter((item) => {
      return definedWhiteArry.includes(item)
    })
    if (newWhiteList.length) {
      EdmUpObj[ID].whitelist = newWhiteList.toString().toLocaleLowerCase()
    } else if (configWhiteList) {
      EdmUpObj[ID].whitelist = configWhiteList.toLocaleLowerCase()
    } else if (EdmUpObj[ID].whitelist) {
      EdmUpObj[ID].whitelist = EdmUpObj[ID].whitelist.toLocaleLowerCase()
    } else {
      EdmUpObj[ID].whitelist = ''
    }
  },
  // 获取 EDMToken 返回的参数
  SetEDMTokenData: function (ID, response) {
    EdmUpObj[ID].enterpriseDocumentToken = response.edmToken
    EdmUpObj[ID].requestTrace = response.traceId || ''
    EdmUpObj[ID].tenantUploadConfig = response.config || {}
    var configWhiteList = EdmUpObj[ID].tenantUploadConfig.fileWhiteList || ''
    EdmUpUtils.getWhiteList(configWhiteList, ID)
    EdmUpObj[ID].allowedSites = response.sites
    // 有用户的输入，就使用用户输入的，没有则看是否有EDMToken中的config，有则使用，都没有则为空值
    EdmUpObj[ID].tenantUploadConfig.uploadMaxQty = EdmUpObj[ID].tenantUploadConfig.uploadMaxQty && Number(EdmUpObj[ID].tenantUploadConfig.uploadMaxQty)
    // 存储的单位是B
    if (EdmUpObj[ID].fileSizeRange && EdmUpObj[ID].fileSizeRange.length === 2) {
      EdmUpObj[ID].tenantUploadConfig.singleFileMaxSize = EdmUpObj[ID].fileSizeRange[1]
    } else {
      EdmUpObj[ID].tenantUploadConfig.singleFileMaxSize = EdmUpObj[ID].tenantUploadConfig.singleFileMaxSize 
                                                          && Number(EdmUpObj[ID].tenantUploadConfig.singleFileMaxSize) * 1024 * 1024
    }
  },
  getRequestEdmTokenFlag: function(xhr, ID, refresh, cnt) {
    if (xhr.status === 200) {
      var response = EdmUpUtils.isEmpty(xhr.response) ? {} : JSON.parse(xhr.response)
      if (response && response.status === 200) {
        EdmUpObj[ID].tokenReplayCount = 0 // token请求成功，重置重试次数
        EdmUpObj[ID].enterpriseDocumentToken = response.result.edmToken
        EdmUpObj[ID].requestTrace = response.result.traceId || ''
        EdmUpObj[ID].tenantUploadConfig = response.result.config || {}
        var configWhiteList = EdmUpObj[ID].tenantUploadConfig.fileWhiteList || ''
        EdmUpUtils.getWhiteList(configWhiteList, ID)
        EdmUpObj[ID].allowedSites = response.result.sites
        // 有用户的输入，就使用用户输入的，没有则看是否有EDMToken中的config，有则使用，都没有则为空值
        EdmUpObj[ID].tenantUploadConfig.uploadMaxQty = EdmUpObj[ID].tenantUploadConfig.uploadMaxQty 
          && Number(EdmUpObj[ID].tenantUploadConfig.uploadMaxQty)
        // 存储的单位是B
        if (EdmUpObj[ID].fileSizeRange && EdmUpObj[ID].fileSizeRange.length === 2) {
          EdmUpObj[ID].tenantUploadConfig.singleFileMaxSize = EdmUpObj[ID].fileSizeRange[1]
        } else {
          EdmUpObj[ID].tenantUploadConfig.singleFileMaxSize = EdmUpObj[ID].tenantUploadConfig.singleFileMaxSize 
                                                              && Number(EdmUpObj[ID].tenantUploadConfig.singleFileMaxSize) * 1024 * 1024
        }
        return true
      } else if (response.edmToken) {
        EdmUpObj[ID].tokenReplayCount = 0 // token请求成功，重置重试次数
        EdmUpUtils.SetEDMTokenData(ID, response)
        return true
      } else {
        response = response || {}
        var errorStatus = response.status || 500
        var errorMessage = response.message || EdmUpObj[ID].uploadErrorCodes.LOAD_TOKEN_ERROR.message
        EdmUpObj[ID].failFunction({
          status: errorStatus,
          message: errorMessage
        })
        return false
      }
    } else if (xhr.status === 0) {
      if (cnt < EdmUpUtils.REPLAY_LIMIT) {
        cnt++
        return EdmUpObj[ID].requestEdmToken(refresh, cnt)
      } else {
        if (EdmUpObj[ID].replayFlag) {
          EdmUpObj[ID].failFunction(EdmUpObj[ID].uploadErrorCodes.NET_EXCEPTION)
          EdmUpObj[ID].replayFlag = false
        }
        return false
      }
    } else {
      EdmUpObj[ID].failFunction({
        status: xhr.status,
        message: EdmUpObj[ID].uploadErrorCodes.LOAD_TOKEN_ERROR.message
      })
      return false
    }
  },
  // 发送 EDMToken 请求
  setRequestEdmToken: function (ID) {
    EdmUpObj[ID].requestEdmToken = function (refresh, cnt = 0) {
      return new Promise((resolve, reject) => {
        if (EdmUpObj[ID].unavailableInstance) {
          return resolve(false)
        }
        if (!refresh && !EdmUpUtils.isEmpty(EdmUpObj[ID].enterpriseDocumentToken)) {
          return resolve(true)
        }
        EdmUpObj[ID].requestTrace = refresh ? '' : EdmUpObj[ID].requestTrace
        var xhr = EdmUpUtils.newXMLHttpRequest(true)
        var url = EdmUpObj[ID].tokenDomain
        var params = EdmUpUtils.setRequestEdmTokenParams(ID)
        url = EdmUpUtils.serRequestEdmTokenIfGEt(ID, url, params)
        xhr.open(EdmUpObj[ID].tokenMethod, url, true)
        if (EdmUpObj[ID].sdkDomainHeader) {
          var valueArr = EdmUpUtils.getValueArr(EdmUpObj[ID].sdkDomainHeader)
          for (var i = 0; i < valueArr.length; i++) {
            xhr.setRequestHeader(valueArr[i], EdmUpObj[ID].sdkDomainHeader[valueArr[i]])
          }
        }
        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4) {
            resolve(EdmUpUtils.getRequestEdmTokenFlag(xhr, ID, refresh, cnt))
          }
        }
        if (EdmUpObj[ID].tokenMethod === 'GET') {
          xhr.send()
        } else {
          var queryBody = EdmUpUtils.setQueryBody(ID, url)
          xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
          xhr.send(JSON.stringify(queryBody))
        }
      })
    }
  },
  paralUploadFailCNMessage: function (ID, numFail, files, curDocuments) {
    let failMessage = `上传的${files.length}个文件中${files.length - curDocuments.length}个上传失败，失败原因如下：\n`
    if (numFail[0].length > 0) {
      failMessage += `${numFail[0].length}个文件格式暂不支持（文件名：`
      for (let i = 0; i < numFail[0].length - 1; i ++) {
        failMessage += `${numFail[0][i]}、`
      }
      failMessage += `${numFail[0][numFail[0].length - 1]}）\n`
    }
    if (numFail[1].length > 0) {
      failMessage += `${numFail[1].length}个文件大小超过限制${convertUnit(EdmUpObj[ID].tenantUploadConfig.singleFileMaxSize)}（文件名：`
      for (let i = 0; i < numFail[1].length - 1; i ++) {
        failMessage += `${numFail[1][i]}、`
      }
      failMessage += `${numFail[1][numFail[1].length - 1]}）\n`
    }
    if (numFail[2].length > 0) {
      failMessage += `${numFail[2].length}个文件大小低于限制${convertUnit(EdmUpObj[ID].fileSizeRange[0])}（文件名：`
      for (let i = 0; i < numFail[2].length - 1; i ++) {
        failMessage += `${numFail[2][i]}、`
      }
      failMessage += `${numFail[2][numFail[2].length - 1]}）\n`
    }
    return failMessage
  },
  paralUploadFailENMessage: function (ID, numFail, files, curDocuments) {
    let failMessage = `Among the ${files.length} ${files.length > 1 ? 'files' : 'file'} uploaded,${files.length - curDocuments.length} ${files.length - curDocuments.length > 1 ? 'files' : 'file'} ${files.length - curDocuments.length > 1 ? 'are' : 'is'} failed for the following reasons:\n`
    if (numFail[0].length > 0) {
      failMessage += `The format of ${numFail[0].length} ${numFail[0].length > 1 ? 'files' : 'file'} is not allowed(file name:`
      for (let i = 0; i < numFail[0].length - 1; i ++) {
        failMessage += `${numFail[0][i]};`
      }
      failMessage += `${numFail[0][numFail[0].length - 1]})\n`
    }
    if (numFail[1].length > 0) {
      failMessage += `The size of ${numFail[1].length} ${numFail[1].length > 1 ? 'files' : 'file'} exceeds the limit of ${convertUnit(EdmUpObj[ID].tenantUploadConfig.singleFileMaxSize)}(file name:`
      for (let i = 0; i < numFail[1].length - 1; i ++) {
        failMessage += `${numFail[1][i]};`
      }
      failMessage += `${numFail[1][numFail[1].length - 1]})\n`
    }
    if (numFail[2].length > 0) {
      failMessage += `The size of ${numFail[2].length} ${numFail[2].length > 1 ? 'files' : 'file'} is lower than the limit of ${convertUnit(EdmUpObj[ID].fileSizeRange[0])}(file name:`
      for (let i = 0; i < numFail[2].length - 1; i ++) {
        failMessage += `${numFail[2][i]};`
      }
      failMessage += `${numFail[2][numFail[2].length - 1]})\n`
    }
    return failMessage
  },
  setParalUploadFailMessage: function(ID, numFail, files, curDocuments) {
    let failMessage = ''
    if (EdmUpObj[ID].edmLanguage === 'zh_CN') {
      failMessage = EdmUpUtils.paralUploadFailCNMessage(ID, numFail, files, curDocuments)
    } else {
      failMessage = EdmUpUtils.paralUploadFailENMessage(ID, numFail, files, curDocuments)
    }
    return failMessage
  },
  paralUploadFail: function(ID, files, curDocuments) {
    if (curDocuments.length < files.length) {
      let failMessage = ''
      let numFail = [[], [], []]
      for (let i = 0; i < EdmUpObj[ID].failMessage.length; i ++) {
        if (EdmUpObj[ID].failMessage[i][0] === 0) {
          numFail[0].push(EdmUpObj[ID].failMessage[i][1])
        } else if (EdmUpObj[ID].failMessage[i][0] === 1) {
          numFail[1].push(EdmUpObj[ID].failMessage[i][1])
        } else {
          numFail[2].push(EdmUpObj[ID].failMessage[i][1])
        }
      }
      failMessage = EdmUpUtils.setParalUploadFailMessage(ID, numFail, files, curDocuments)
      EdmUpObj[ID].failMessage = []
      EdmUpObj[ID].failFunction({status: 90012, message: failMessage, failNum: files.length - curDocuments.length}) 
    }
  },
  // 设置进度条
  setDonePart: function (ID) {
    EdmUpObj[ID].donePart = function (ID) {
      EdmUpObj[ID].uploadHandleProgress(EdmUpObj[ID].refreshProgress()) // 进度条
      EdmUpObj[ID].done = EdmUpObj[ID].done + 1
      if (EdmUpObj[ID].done >= EdmUpObj[ID].fileCount) {
        clearInterval(EdmUpObj[ID].edmtokenInterval)
        EdmUpObj[ID].uploading = false
        EdmUpUtils.paralUploadFail(ID, EdmUpObj[ID].files, EdmUpObj[ID].curDocuments)
        EdmUpObj[ID].notice({
          type: 'finish',
          data: EdmUpObj[ID].refreshProgress(true)
        })
        EdmUpObj[ID].uploadIndex = 0
      } else {
        if (!EdmUpObj[ID].parallelUpload) {
          EdmUpObj[ID].uploadIndex = EdmUpObj[ID].uploadIndex + 1
          EdmUpUtils.uploadPortal(ID)
        }
      }
    }
  },
  // 进度条更新
  setRefreshProgress: function (ID) {
    EdmUpObj[ID].refreshProgress = function (isDone) {
      var result = []
      for (var key in EdmUpObj[ID].cacheDocuments) {
        var item = EdmUpObj[ID].cacheDocuments[key]
        var progress = 0
        if (item.chunkSize >= 0) {
          var denominator = item.chunkSize + 2
          progress = Math.round(item.progress * 100) / denominator
        }
        progress = isDone ? 100 : progress
        result.push({
          file: item,
          progress: progress,
          cacheSign: key
        })
      }
      return result
    }
  },
  checkWhitelist: function(ID) {
    if (EdmUpObj[ID].whitelist) {
      let suffixResult = []
      const whitelist = EdmUpObj[ID].whitelist + ','
      EdmUpObj[ID].cacheDocumentKeys.forEach(fileKey => {
        const docname = EdmUpObj[ID].cacheDocuments[fileKey].name
        let isNoSuffixFile = true
        let suf = ''
        if (docname.indexOf('.') > -1) {
          isNoSuffixFile = false
          suf = docname.substring(docname.lastIndexOf('.') + 1, docname.length).toLowerCase()
        } else {
          if (whitelist.toLowerCase().indexOf('.null') === -1 && whitelist.toLowerCase().indexOf('.*') === -1) {
            suffixResult.push('.null')
          }
        }
        if (!isNoSuffixFile && whitelist.toLowerCase().indexOf('.' + suf + ',') === -1 && whitelist.toLowerCase().indexOf('.*') === -1) {
          suffixResult.push('.' + suf)
        }
      })
      return Array.from(new Set(suffixResult)).join(",")
    }
    return ''
  },
  // 对文件进行校验，根据文件大小选择不同的上传方法
  setUploadPortal: function () {
    EdmUpUtils.uploadPortal = function (ID, isInit, files) {
      var documentKey = EdmUpObj[ID].cacheDocumentKeys[EdmUpObj[ID].uploadIndex]
      var file = EdmUpObj[ID].cacheDocuments[documentKey]
      // 校验不通过直接返回，不进行上传
      if (!EdmUpUtils.fileVerification(ID, file, isInit)) {
        return false
      }
      if (EdmUpObj[ID].cacheDocuments[file.cacheSign].abort === true) {
        EdmUpObj[ID].donePart(ID) // 上传完成
        EdmUpUtils.updateDocStatus(ID, file.cacheSign, 504, 'The upload is canceled,上传被取消')
        return
      }
      if (EdmUpObj[ID].chunkAtom && file.size > EdmUpUtils.CHUNK_ATOM * 1024 * 1024) {
        EdmUpObj[ID].largeDocumentUpload(file, ID)
      } else if (!EdmUpObj[ID].chunkAtom && file.size > EdmUpUtils.CHUNK_LIMIT * 1024 * 1024) {
        EdmUpObj[ID].largeDocumentUpload(file, ID)
      } else {
        EdmUpObj[ID].smallDocumentUpload(file, ID)
      }
    }
  },
  // 创建文件输入框
  setCreateTempInputFile: function (ID) {
    EdmUpObj[ID].createTempInputFile = function (bind) {
      var domId = EdmUpUtils.guid()
      var input = null
      if (EdmUpUtils.isIE) {
        try {
          input = document.createElement(
            '<input id="uploadinput" onchange="setBlogrollImageName(this)" name="' + domId + '"/>'
          )
        } catch (error) {
          input = document.createElement('input')
          input.setAttribute('name', domId)
        }
      } else {
        input = document.createElement('input')
        input.setAttribute('name', domId)
      }
      if (EdmUpObj[ID].allowedFolder) {
        input.setAttribute('webkitdirectory', true)
        input.setAttribute('directory', true)
      }
      input.style.display = 'none'
      input.setAttribute('type', 'file')
      // 是否多文档
      if (!EdmUpObj[ID].single) {
        input.setAttribute('multiple', true)
      }
      // 白名单
      input.setAttribute('accept', EdmUpObj[ID].whitelist || EdmUpObj[ID].tenantUploadConfig.fileWhiteList)
      input.addEventListener('change', bind)
      document.body.appendChild(input)
      EdmUpObj[ID].tempInputFileDocument = input
    }
  },
  // 文件请求失败处理
  setFileResponseErrorHandler: function (ID) {
    EdmUpObj[ID].fileResponseErrorHandler = function (f, response, isContinue, cacheSign) {
      if (EdmUpObj[ID].fileCount === 1 || EdmUpObj[ID].breakWhenError) {
        if (EdmUpObj[ID].isStop === true) {
          return
        }
        if (EdmUpObj[ID].cacheDocuments[cacheSign] && EdmUpObj[ID].cacheDocuments[cacheSign].abort) {
          EdmUpUtils.updateDocStatus(ID, cacheSign, 0, 'The upload is canceled,上传被取消')
          EdmUpObj[ID].donePart(ID)
        } else {
          // 后端校验，拦截信息，修改提示语
          switch (response.status) {
            case 11017:
              // 提取后缀
              const suffix = /\(([^)]+)\)/.exec(response.message)[1]
              response.message = EdmUpObj[ID].uploadErrorCodes.FILENAME_IS_NOTIN_WHITELIST.message.replace('.suffix', suffix)
              break;
            case 11023:
              const messageArr = response.message.split(' ')
              const size = messageArr[messageArr.length - 1]
              response.message = EdmUpObj[ID].uploadErrorCodes.MAX_SIZE.message + `${convertUnit(size)}` 
              break;
            case 12087:
              const num = response.message.match(/\d+(\.\d+)?/)[0]
              response.message = EdmUpObj[ID].uploadErrorCodes.EXCEEDS_LIMIT
                                   + num
                                   + `${EdmUpObj[ID].edmLanguage === 'zh_CN' ? '个' : ''}`
              break;
            case 110231:
              response.message = EdmUpObj[ID].uploadErrorCodes.ZERO_SIZE_FILE.message
              break;
            default:
              break;
          }
          EdmUpObj[ID].failFunction(response)
        }
        EdmUpObj[ID].isStop = true
      } else if (isContinue && EdmUpObj[ID].fileCount !== 1) {
        if (EdmUpObj[ID].cacheDocuments[cacheSign] && EdmUpObj[ID].cacheDocuments[cacheSign].abort) {
          EdmUpUtils.updateDocStatus(ID, cacheSign, 0, 'The upload is canceled,上传被取消')
        } else {
          EdmUpUtils.updateDocStatus(ID, cacheSign, 407, '未知错误,上传失败')
        }
        if (!EdmUpObj[ID].cacheDocuments[cacheSign].cancelled) {
          EdmUpObj[ID].donePart(ID)
        }
        EdmUpObj[ID].cacheDocuments[cacheSign].cancelled = true
      } else {
        if (response.status === 110231) {
          response.message = EdmUpObj[ID].uploadErrorCodes.ZERO_SIZE_FILE.message
        }
        EdmUpObj[ID].notice({
          type: 'fail',
          data: f,
          message: response
        })
      }
    }
  },
  // 请求失败处理
  setResponseErrorResolve: function (ID) {
    EdmUpObj[ID].responseErrorResolve = function (f, xhr, recall) {
      if (xhr.status === 401) {
        if (EdmUpObj[ID].tokenReplayCount >= EdmUpUtils.TOKEN_REPLAY_LIMIT) {
          EdmUpObj[ID].fileResponseErrorHandler(f, EdmUpObj[ID].uploadErrorCodes.TOKEN_INVALID)
          return EdmUpUtils.EMPTY
        } else {
          EdmUpObj[ID].tokenReplayCount = EdmUpObj[ID].tokenReplayCount + 1
          EdmUpObj[ID].requestEdmToken(true).then((tokenFlag) => {
            if (tokenFlag) {
              return recall()
            } else {
              return EdmUpUtils.EMPTY
            }
          })
        }
      } else if (xhr.status === 403) {
        EdmUpObj[ID].fileResponseErrorHandler(f, EdmUpObj[ID].uploadErrorCodes.NO_PERMISSION)
        return EdmUpUtils.EMPTY
      } else {
        EdmUpObj[ID].fileResponseErrorHandler(f, EdmUpObj[ID].uploadErrorCodes.UPLOAD_ERROR)
        return EdmUpUtils.EMPTY
      }
    }
  },
  // 文件块上传记录，当文件块上传的进度等于文件块数量进行文件块的合并
  setProgressAutoIncrement: function (ID) {
    EdmUpObj[ID].progressAutoIncrement = function (key, ID) {
      var data = EdmUpObj[ID].cacheDocuments[key]
      EdmUpObj[ID].cacheDocuments[key].progress = data.progress + 1
      if (data.chunkSize > 0 && EdmUpObj[ID].cacheDocuments[key].progress - 1 === data.chunkSize) {
        EdmUpObj[ID].mergeSegment(data.docId, ID)
      }
    }
  },
  // 获取用户在构造函数中传入的参数
  getMergeForm: function (ID, docId) {
    var mergeForm = new FormData()
    mergeForm.append('docId', docId)
    if (EdmUpObj[ID].isCheckCode === 'Y') {
      mergeForm.append('isCheckCode', EdmUpObj[ID].isCheckCode)
    }
    if (EdmUpObj[ID].ownerAppId) {
      mergeForm.append('ownerAppId', EdmUpObj[ID].ownerAppId)
    }
    if (EdmUpObj[ID].finalSite) {
      mergeForm.append('finalSite', EdmUpObj[ID].finalSite)
    }
    if (EdmUpObj[ID].cacheSite) {
      mergeForm.append('cacheSite', EdmUpObj[ID].cacheSite)
    }
    if (EdmUpObj[ID].updateFile) {
      mergeForm.append('updateFile', EdmUpObj[ID].updateFile)
    }
    if (EdmUpObj[ID].allowedFolder) {
      const documentKey = EdmUpObj[ID].cacheDocumentKeys[EdmUpObj[ID].uploadIndex]
      const webkitRelativePath = EdmUpObj[ID].cacheDocuments[documentKey].webkitRelativePath
      const fileArry = webkitRelativePath.split('/')
      const filePath = fileArry.slice(0, fileArry.length - 1).join('/') + '/'
      mergeForm.append('filePath', filePath)
    }
    if (EdmUpObj[ID].userId) {
      mergeForm.append('userId', EdmUpObj[ID].userId)
    }
    if (EdmUpObj[ID].tagIds) {
      mergeForm.append('tagIds', EdmUpObj[ID].tagIds)
    }
    return mergeForm
  },
  requestFail: function (ID, cacheSign) {
    !EdmUpUtils.getOnLine()
      ? EdmUpUtils.updateDocStatus(ID, cacheSign, 404, 'The network is exception.;网络异常')
      : EdmUpUtils.updateDocStatus(ID, cacheSign, 407, '未知错误,上传失败')
    if (EdmUpObj[ID].fileCount === 1) {
      if (EdmUpObj[ID].replayFlag) {
        !EdmUpUtils.getOnLine()
          ? EdmUpObj[ID].failFunction(EdmUpObj[ID].uploadErrorCodes.NET_EXCEPTION)
          : EdmUpObj[ID].failFunction(EdmUpObj[ID].uploadErrorCodes.UNKNOWN_ERROR)
        EdmUpObj[ID].replayFlag = false
      }
      return false
    }
    EdmUpObj[ID].donePart(ID)
  },
  // 文件块合并
  setMergeSegment: function (ID) {
    // 合并
    EdmUpObj[ID].mergeSegment = function (docId, ID, cnt = 0) {
      // 合并的回调
      var file = {}
      var currentSign = ''
      for (var sign in EdmUpObj[ID].cacheDocuments) {
        if (EdmUpObj[ID].cacheDocuments[sign].docId === docId) {
          file = EdmUpObj[ID].cacheDocuments[sign]
          currentSign = sign
          break
        }
      }
      if (EdmUpObj[ID].cacheDocuments[currentSign].abort === true) {
        EdmUpUtils.updateDocStatus(ID, currentSign, 0, 'The upload is canceled,上传被取消')
        EdmUpObj[ID].donePart(ID) // 上传完成
        return
      }
      EdmUpUtils.updateDocStatus(ID, currentSign, 106, 'The document is being merged;文档正在合并中')
      var mergeForm = EdmUpUtils.getMergeForm(ID, docId)
      var XHRupload = EdmUpUtils.newXMLHttpRequest(EdmUpObj[ID].withCredentials)
      var url = EdmUpObj[ID].edmServiceDomain + EdmUpObj[ID].contextPath.chunkMerge
      XHRupload.open('POST', url, true)
      XHRupload.setRequestHeader(EdmUpUtils.HEADERS.APP_ID, EdmUpObj[ID].appId)
      XHRupload.setRequestHeader(EdmUpUtils.HEADERS.TRACE_ID, EdmUpObj[ID].requestTrace)
      XHRupload.setRequestHeader(EdmUpUtils.HEADERS.TOKEN, EdmUpObj[ID].enterpriseDocumentToken)
      XHRupload.setRequestHeader('x-csrf-token', EdmUpObj[ID].xCsrfToken)
      
      XHRupload.onreadystatechange = function () {
        if (XHRupload.readyState === 4) {
          if (XHRupload.status === 200) {
            // 格式，回调
            var response = JSON.parse(XHRupload.response)
            if (response.status === 200) {
              file.levelcode = response.result.levelCode
              file.serverName = response.result.serverName
              file.version = response.result.version
              file.docRelativePath = response.result.docRelativePath
              EdmUpObj[ID].progressAutoIncrement(currentSign, ID)
              EdmUpUtils.updateDocStatus(ID, currentSign, 200, 'Upload completed;上传完成')
              EdmUpObj[ID].donePart(ID)
              clearInterval(EdmUpUtils.NetSpeedInterval)
              EdmUpObj[ID].cacheDocuments[currentSign].networkSpeed = 0
              EdmUpUtils.Firstflag = true
            } else {
              EdmUpObj[ID].responseErrorResolve(docId, XHRupload, function () {
                EdmUpObj[ID].mergeSegment(docId, ID)
              })
            }
            localStorage.removeItem(file.name + '#' + file.size + '#' + file.lastModified)
            const uploadingKey = `${file.name}#${file.size}#${file.lastModified}#uploading`
            localStorage.removeItem(uploadingKey)
            file.resumableUpload = false
            return true
          } else if (XHRupload.status === 0) {
            clearInterval(EdmUpUtils.NetSpeedInterval)
            EdmUpUtils.Firstflag = true
            if (cnt < EdmUpUtils.REPLAY_LIMIT) {
              cnt ++
              EdmUpObj[ID].mergeSegment(docId, ID, cnt)
            } else {
              EdmUpUtils.requestFail(ID, currentSign)
            }
          } else {
            clearInterval(EdmUpUtils.NetSpeedInterval)
            EdmUpUtils.Firstflag = true
            EdmUpObj[ID].int = null
            EdmUpObj[ID].fileResponseErrorHandler(f, EdmUpObj[ID].uploadErrorCodes.UPLOAD_ERROR)
          }
          localStorage.removeItem(file.name + '#' + file.size + '#' + file.lastModified)
          file.resumableUpload = false
          return false
        }
      }

      XHRupload.send(mergeForm)
    }
  },
  // 获取用户在构造函数中传入的字段数据
  getSmUploadSubmitForm: function (f, ID) {
    var submitForm = new FormData()
    submitForm.append('multipartFile', f)
    if (EdmUpObj[ID].isCheckCode === 'Y') {
      submitForm.append('checkCode', f.checkCode)
      submitForm.append('isCheckCode', EdmUpObj[ID].isCheckCode)
    }
    if (EdmUpObj[ID].encryptType) {
      submitForm.append('encryptType', EdmUpObj[ID].encryptType)
    }
    if (EdmUpObj[ID].encryptScope) {
      submitForm.append('encryptScope', EdmUpObj[ID].encryptScope)
    }
    if (EdmUpObj[ID].ownerAppId) {
      submitForm.append('ownerAppId', EdmUpObj[ID].ownerAppId)
    }
    if (EdmUpObj[ID].finalSite) {
      submitForm.append('finalSite', EdmUpObj[ID].finalSite)
    }
    if (EdmUpObj[ID].cacheSite) {
      submitForm.append('cacheSite', EdmUpObj[ID].cacheSite)
    }
    if (EdmUpObj[ID].updateFile) {
      submitForm.append('updateFile', EdmUpObj[ID].updateFile)
      submitForm.append('docId', EdmUpObj[ID].docId)
    }
    if (EdmUpObj[ID].userId) {
      submitForm.append('userId', EdmUpObj[ID].userId)
    }
    if (EdmUpObj[ID].tagIds) {
      submitForm.append('tagIds', EdmUpObj[ID].tagIds)
    }
    return submitForm
  },
  // 小文件上传请求回调
  smallUploadHandling: function (f, xhr, ID, cnt) {
    if (EdmUpObj[ID].cacheDocuments[f.cacheSign].abort === true) {
      EdmUpUtils.updateDocStatus(ID, f.cacheSign, 504, 'The upload is canceled,上传被取消')
      xhr.abort()
      EdmUpObj[ID].donePart(ID)
      return
    }
    if (xhr.readyState === 3) {
    }
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        var response = JSON.parse(xhr.response)
        if (response.status === 200) {
          EdmUpObj[ID].cacheDocuments[f.cacheSign].progress = 2
          EdmUpObj[ID].cacheDocuments[f.cacheSign].docId = response.result.docId
          EdmUpObj[ID].cacheDocuments[f.cacheSign].levelcode = response.result.levelCode
          EdmUpObj[ID].cacheDocuments[f.cacheSign].serverName = response.result.serverName
          EdmUpObj[ID].cacheDocuments[f.cacheSign].version = response.result.version
          EdmUpObj[ID].cacheDocuments[f.cacheSign].docRelativePath = response.result.docRelativePath
          EdmUpUtils.updateDocStatus(ID, f.cacheSign, 200, 'Upload completed;上传完成')
          EdmUpObj[ID].donePart(ID) // 上传完成
        } else if (response.status === 11005) {
          EdmUpObj[ID].donePart(ID)
          EdmUpObj[ID].fileResponseErrorHandler(f, EdmUpObj[ID].uploadErrorCodes.SPECIAL_CHARACTERS_EXIST)
        } else {
          EdmUpUtils.updateDocStatus(ID, f.cacheSign, 300, response.message)
          EdmUpObj[ID].donePart(ID)
          var CustomizedErrorCodes = customizedError(response.status, response.message)
          EdmUpObj[ID].fileResponseErrorHandler(f, CustomizedErrorCodes)
          clearInterval(EdmUpUtils.NetSpeedInterval)
          EdmUpObj[ID].cacheDocuments[f.cacheSign].networkSpeed = 0
          EdmUpUtils.Firstflag = true
          return EdmUpUtils.EMPTY
        }
        clearInterval(EdmUpUtils.NetSpeedInterval)
        EdmUpObj[ID].cacheDocuments[f.cacheSign].networkSpeed = 0
        EdmUpUtils.Firstflag = true
        return true
      } else if (xhr.status === 0) {
        if (cnt < EdmUpUtils.REPLAY_LIMIT) {
          cnt ++
          EdmUpObj[ID].SmallDocumentUploadXhh(f, ID, cnt)
        } else {
          EdmUpUtils.requestFail(ID, f.cacheSign)
        }
      } else {
        EdmUpObj[ID].responseErrorResolve(f, xhr, function () {
          EdmUpObj[ID].smallDocumentUpload(f, ID)
        })
      }
      clearInterval(EdmUpUtils.NetSpeedInterval)
      EdmUpObj[ID].cacheDocuments[f.cacheSign].networkSpeed = 0
      EdmUpUtils.Firstflag = true
      return false
    }
  },
  // 上传状态更新回调
  updateDocStatus: function (ID, cacheSign, statusCode, message) {
    if (EdmUpObj[ID].cacheDocuments[cacheSign]) {
      EdmUpObj[ID].cacheDocuments[cacheSign].status = {
        statusCode: statusCode,
        message: message
      }
      if (statusCode === 407 || statusCode === 0) { // 失败407、取消0，不回传docId
        EdmUpObj[ID].cacheDocuments[cacheSign].docId = ''
      }
      EdmUpObj[ID].uploadStatus(EdmUpObj[ID].cacheDocuments)
    }
  },
  // 创建 Ajax 请求，进行小文件上传
  setSmallDocumentUploadXhh: function (ID) {
    EdmUpObj[ID].SmallDocumentUploadXhh = function (f, ID, cnt = 0) {
      if (EdmUpObj[ID].cacheDocuments[f.cacheSign].abort === true) {
        EdmUpUtils.updateDocStatus(ID, f.cacheSign, 504, 'The upload is canceled,上传被取消')
        EdmUpObj[ID].donePart(ID)
        return
      }
      EdmUpObj[ID].cacheDocuments[f.cacheSign].progress = 0
      EdmUpObj[ID].progressAutoIncrement(f.cacheSign, ID)
      EdmUpObj[ID].uploadHandleProgress(EdmUpObj[ID].refreshProgress())
      var submitForm = EdmUpUtils.getSmUploadSubmitForm(f, ID)
      var url = EdmUpObj[ID].edmServiceDomain + EdmUpObj[ID].contextPath.small
      EdmUpObj[ID].cacheDocuments[f.cacheSign].xhr = EdmUpUtils.newXMLHttpRequest(EdmUpObj[ID].withCredentials)
      EdmUpObj[ID].cacheDocuments[f.cacheSign].xhr.open('PUT', url, true)
      EdmUpObj[ID].cacheDocuments[f.cacheSign].xhr.setRequestHeader(EdmUpUtils.HEADERS.APP_ID, EdmUpObj[ID].appId)
      EdmUpObj[ID].cacheDocuments[f.cacheSign].xhr.setRequestHeader(
        EdmUpUtils.HEADERS.TRACE_ID,
        EdmUpObj[ID].requestTrace
      )
      EdmUpObj[ID].cacheDocuments[f.cacheSign].xhr.setRequestHeader(
        EdmUpUtils.HEADERS.TOKEN,
        EdmUpObj[ID].enterpriseDocumentToken
      )
      EdmUpObj[ID].cacheDocuments[f.cacheSign].xhr.setRequestHeader('x-csrf-token', EdmUpObj[ID].xCsrfToken)
      EdmUpObj[ID].cacheDocuments[f.cacheSign].xhr.onreadystatechange = function () {
        EdmUpUtils.smallUploadHandling(f, EdmUpObj[ID].cacheDocuments[f.cacheSign].xhr, ID, cnt)
      }
      var s = true
      var NetworkSpeed = setInterval(() => {
        s = true
      }, 1000)
      EdmUpObj[ID].cacheDocuments[f.cacheSign].xhr.upload.addEventListener(
        'progress',
        function (e) {
          if (s === true) {
            EdmUpUtils.CalculateNetworkSpeed(ID, e, f)
            s = false
          }
          if (e.loaded === e.total) {
            clearInterval(NetworkSpeed)
          }
        },
        false
      )
      EdmUpUtils.updateDocStatus(ID, f.cacheSign, 102, 'Uploading;正在上传中')
      EdmUpObj[ID].cacheDocuments[f.cacheSign].xhr.send(submitForm)
    }
  },
  NetworkSpeedObject: {},
  TemnetworkSpeed: 0,
  Firstflag: true,
  NetSpeedInterval: null,
  // 上传速度计算
  FirstNetworkSpeed(ID, cacheSign) {
    if (EdmUpUtils.Firstflag) {
      EdmUpUtils.Firstflag = false
      EdmUpUtils.NetSpeedInterval = setInterval(() => {
        EdmUpObj[ID].networkSpeed(EdmUpUtils.NetworkSpeedObject)
        EdmUpUtils.TemnetworkSpeed = 0
      }, 1000)
    }
  },
  CalculateNetworkSpeed(ID, e, f) {
    if (EdmUpUtils.NetworkSpeedObject[f.cacheSign]) {
      var lastLoaded = EdmUpUtils.NetworkSpeedObject[f.cacheSign].Nspeed
      if (e.loaded - lastLoaded > 0) {
        EdmUpUtils.NetworkSpeedObject[f.cacheSign].Nspeed = e.loaded - lastLoaded
      }
      EdmUpUtils.NetworkSpeedObject[f.cacheSign].loaded = e.loaded
    } else {
      EdmUpUtils.NetworkSpeedObject[f.cacheSign] = {
        file: f,
        Nspeed: e.loaded,
        loaded: e.loaded,
        total: e.total
      }
    }
    EdmUpUtils.getTemnetworkSpeed(ID, EdmUpUtils.NetworkSpeedObject, f.cacheSign)
    EdmUpUtils.FirstNetworkSpeed(ID, f.cacheSign)
  },
  getTemnetworkSpeed(ID, NetworkSpeedObject, cacheSign) {
    let { Nspeed } = EdmUpUtils.NetworkSpeedObject[cacheSign]
    EdmUpUtils.TemnetworkSpeed += Nspeed
    EdmUpUtils.NetworkSpeedObject[cacheSign].NetworkSpeed = EdmUpUtils.TemnetworkSpeed
  },
  calcHashCode(algoMap, file){
    return new Promise((resolve ,reject) => {
      var reader = new FileReader()
      reader.readAsArrayBuffer(file)
      reader.onload = function (e) {
        let hashObj = {};
        if (algoMap.SHA256) {
          const wordArray = CryptoJS.lib.WordArray.create(reader.result)
          hashObj.SHA256 = CryptoJS.SHA256(wordArray).toString()
        } 
        if (algoMap.MD5) {
          hashObj.MD5 = md5(e.target.result)
        }
        resolve(hashObj)
      }
      reader.onerror = function (e) {
        reject(e)
      }
    })
  },
  // 小文件上传准备
  setSmallDocumentUpload: function (ID) {
    EdmUpObj[ID].smallDocumentUpload = function (f, ID) {
      if (EdmUpObj[ID].cacheDocuments[f.cacheSign].abort === true) {
        EdmUpObj[ID].donePart(ID) // 上传完成
        EdmUpUtils.updateDocStatus(ID, f.cacheSign, 504, 'The upload is canceled,上传被取消')
        return
      }
      f.chunkSize = 0
      if (EdmUpObj[ID].isCheckCode === 'Y' || EdmUpObj[ID].duplicateCheckType === "SHA256" || EdmUpObj[ID].duplicateCheckType === "MD5") {
        EdmUpUtils.updateDocStatus(ID, f.cacheSign, 103, 'Document is calculating encryption;文档正在计算加密中')
        let algoMap = {
          SHA256: EdmUpObj[ID].isCheckCode === 'Y' || EdmUpObj[ID].duplicateCheckType === "SHA256",
          MD5: EdmUpObj[ID].duplicateCheckType === "MD5"
        }
        EdmUpUtils.calcHashCode(algoMap, f).then((hashObj)=> {
          if (EdmUpObj[ID].isCheckCode === 'Y') {
            f.checkCode = hashObj.SHA256
          }
          if (EdmUpObj[ID].duplicateCheckType === "SHA256") {
            f.duplicateCheckCode = hashObj.SHA256
          } else if (EdmUpObj[ID].duplicateCheckType === "MD5") {
            f.duplicateCheckCode = hashObj.MD5
          }
          EdmUpObj[ID].SmallDocumentUploadXhh(f, ID)
        }).catch((error)=> {
          EdmUpUtils.updateDocStatus(ID, f.cacheSign, 407, '未知错误,上传失败')
          EdmUpObj[ID].fileCount === 1
            ? EdmUpObj[ID].failFunction(EdmUpObj[ID].uploadErrorCodes.UNKNOWN_ERROR)
            : EdmUpObj[ID].donePart(ID)
        })
      } else {
        EdmUpObj[ID].SmallDocumentUploadXhh(f, ID)
      }
    }
  },
  // 大文件分块上传数据初始化
  setSegmentInitData: function (f, ID) {
    var initData = new FormData()
    initData.append('fileName', f.name)
    initData.append('chunks', f.chunkSize)
    initData.append('fileSize', f.size)
    initData.append('enableChunkConfig', true)
    if (f.docId && !EdmUpObj[ID].updateFile) {
      initData.append('docId', f.docId)
    }
    if (EdmUpObj[ID].isCheckCode === 'Y') {
      initData.append('isCheckCode', EdmUpObj[ID].isCheckCode)
      if (EdmUpObj[ID].isEntireCheckCode !== 'N') {
        initData.append('checkCode', f.checkCode)
      }
    }
    if (EdmUpObj[ID].encryptType) {
      initData.append('encryptType', EdmUpObj[ID].encryptType)
    }
    if (EdmUpObj[ID].encryptScope) {
      initData.append('encryptScope', EdmUpObj[ID].encryptScope)
    }
    if (EdmUpObj[ID].ownerAppId) {
      initData.append('ownerAppId', EdmUpObj[ID].ownerAppId)
    }
    if (EdmUpObj[ID].finalSite) {
      initData.append('finalSite', EdmUpObj[ID].finalSite)
    }
    if (EdmUpObj[ID].cacheSite) {
      initData.append('cacheSite', EdmUpObj[ID].cacheSite)
    }
    if (EdmUpObj[ID].updateFile) {
      initData.append('updateFile', EdmUpObj[ID].updateFile)
      initData.append('docId', EdmUpObj[ID].docId)
    }
    return initData
  },
  // 大文件上传初始化请求回调
  handlingSegmentUploadInit: function (xhrUpload, f, ID, cnt) {
    if (xhrUpload.status === 200) {
      var response = JSON.parse(xhrUpload.response)
      if (response.status === 200) {
        f.records = response.result.chunks
        f.docId = response.result.docId
        localStorage.setItem(f.name + '#' + f.size + '#' + f.lastModified, response.result.docId)
        return response.result
      } else if (response.status === 100) {
        var CustomizedErrorCodes = customizedError(response.status, response.message)
        EdmUpObj[ID].fileResponseErrorHandler(f, CustomizedErrorCodes)
        EdmUpUtils.updateDocStatus(ID, f.cacheSign, 300, response.message)
        return EdmUpUtils.EMPTY
      } else {
        EdmUpUtils.updateDocStatus(ID, f.cacheSign, 300, response.message)
        var CustomizedErrorCodes = customizedError(response.status, response.message)
        EdmUpObj[ID].fileResponseErrorHandler(f, CustomizedErrorCodes)
        return EdmUpUtils.EMPTY
      }
    } else if (xhrUpload.status === 0) {
      if (cnt < EdmUpUtils.REPLAY_LIMIT) {
        cnt ++
        return EdmUpObj[ID].segmentUploadInit(f, cnt, true)
      } else {
        EdmUpUtils.requestFail(ID, f.cacheSign)
        return EdmUpUtils.EMPTY
      }
    } else {
      return EdmUpObj[ID].responseErrorResolve(f, xhrUpload, function () {
        return EdmUpObj[ID].segmentUploadInit(f, 0, true)
      })
    }
  },
  /**
   * 大文件上传初始化接口
   * @param {*} ID 
   * @param {File} f 文档对象
   * @param {Number} cnt 重试次数
   * @param {Boolean} isRetry 是否是重试，如果重试则不判断重复上传
   */
  setSegmentUploadInit: function (ID) {
    // 大文件上传的初始化接口
    EdmUpObj[ID].segmentUploadInit = function (f, cnt = 0, isRetry = false) {
      return new Promise((resolve, reject)=> {
        if (EdmUpObj[ID].cacheDocuments[f.cacheSign].abort === true) {
          EdmUpUtils.updateDocStatus(ID, f.cacheSign, 0, 'The upload is canceled,上传被取消')
          EdmUpObj[ID].donePart(ID) // 上传完成
          return resolve('')
        }
        EdmUpObj[ID].uploadingFile = f
        if (localStorage.getItem(`${f.name}#${f.size}#${f.lastModified}#uploading`) && isRetry === false) {
          EdmUpObj[ID].uploadErrorCodes.FILE_UPLOADING.message = EdmUpObj[ID].uploadErrorCodes.FILE_UPLOADING.message.replace(/{name}+/, f.name)
          EdmUpObj[ID].failFunction(EdmUpObj[ID].uploadErrorCodes.FILE_UPLOADING, 'uploading')
          return resolve('')
        }
        const uploadingKey = `${f.name}#${f.size}#${f.lastModified}#uploading`
        localStorage.setItem(uploadingKey, 'uploading')
        var initData = EdmUpUtils.setSegmentInitData(f, ID)
        var url = EdmUpObj[ID].edmServiceDomain + EdmUpObj[ID].contextPath.chunkInit
        var xhrUpload = EdmUpUtils.newXMLHttpRequest(EdmUpObj[ID].withCredentials)
        xhrUpload.onreadystatechange = function () {
          if (xhrUpload.readyState === 4) {
            resolve(EdmUpUtils.handlingSegmentUploadInit(xhrUpload, f, ID, cnt))
          }
        }
        xhrUpload.open('POST', url, true)
        xhrUpload.setRequestHeader(EdmUpUtils.HEADERS.APP_ID, EdmUpObj[ID].appId)
        xhrUpload.setRequestHeader(EdmUpUtils.HEADERS.TRACE_ID, EdmUpObj[ID].requestTrace)
        xhrUpload.setRequestHeader(EdmUpUtils.HEADERS.TOKEN, EdmUpObj[ID].enterpriseDocumentToken)
        xhrUpload.setRequestHeader('x-csrf-token', EdmUpObj[ID].xCsrfToken)
        xhrUpload.send(initData)
        EdmUpUtils.updateDocStatus(ID, f.cacheSign, 106, 'Initializing the fragment.;分片初始化中')
      })
    }
  },
  // 获取大文件上传分块数据
  getSegmentUploadData: function (ID, atom, slicingCheckCode) {
    var segmentUploadData = new FormData()
    segmentUploadData.append('docId', EdmUpObj[ID].docId || atom.docId)
    var chunkOrder = EdmUpUtils.CHUNK_START_ZERO ? atom.chunk : atom.chunk + 1
    segmentUploadData.append('chunk', chunkOrder)
    segmentUploadData.append('multipartFile', atom)
    if (EdmUpObj[ID].isCheckCode === 'Y') {
      segmentUploadData.append('isCheckCode', EdmUpObj[ID].isCheckCode)
      segmentUploadData.append('checkCode', slicingCheckCode)
    }
    if (EdmUpObj[ID].ownerAppId) {
      segmentUploadData.append('ownerAppId', EdmUpObj[ID].ownerAppId)
    }
    if (EdmUpObj[ID].finalSite) {
      segmentUploadData.append('finalSite', EdmUpObj[ID].finalSite)
    }
    if (EdmUpObj[ID].cacheSite) {
      segmentUploadData.append('cacheSite', EdmUpObj[ID].cacheSite)
    }
    if (EdmUpObj[ID].updateFile) {
      segmentUploadData.append('updateFile', EdmUpObj[ID].updateFile)
    }
    return segmentUploadData
  },
  // 大文件分片上传请求回调
  handingUploadXhr: function (uploadXhr, atom, batchIndex, ID, slicingCheckCode, cacheSign, cnt) {
    if (uploadXhr.readyState === 4) {
      if (uploadXhr.status === 200) {
        var response = JSON.parse(uploadXhr.response)
        if (response.status === 200) {
          var key = atom.docId + '-' + batchIndex
          var count = EdmUpObj[ID].batchQueue[key]
          EdmUpObj[ID].batchQueue[key] = count + 1
          EdmUpObj[ID].progressAutoIncrement(atom.cacheSign, ID)
          EdmUpObj[ID].uploadHandleProgress(EdmUpObj[ID].refreshProgress())
          return true
        } else {
          // 处理出错信息
          var countReplay = EdmUpObj[ID].replayAtoms[atom.docId + '-' + atom.chunk] || 0
          if (countReplay && countReplay >= EdmUpUtils.REPLAY_LIMIT) {
            if (response.status === 11005) {
              EdmUpObj[ID].fileResponseErrorHandler(atom, EdmUpObj[ID].uploadErrorCodes.SPECIAL_CHARACTERS_EXIST)
            } else {
              EdmUpObj[ID].fileResponseErrorHandler(atom, response)
            }
            delete EdmUpObj[ID].replayAtoms[atom.docId + '-' + atom.chunk]
          } else {
            EdmUpObj[ID].replayAtoms[atom.docId + '-' + atom.chunk] = countReplay + 1
            EdmUpObj[ID].segmentUpload(batchIndex, atom, cacheSign)
          }
          return true
        }
      } else if (uploadXhr.status === 0) {
        if (cnt < EdmUpUtils.REPLAY_LIMIT) {
          cnt ++
          EdmUpObj[ID].SegmentUploadXhh(ID, batchIndex, atom, slicingCheckCode, cacheSign, cnt)
        } else {
          if (!EdmUpUtils.getOnLine()) {
            EdmUpUtils.updateDocStatus(ID, cacheSign, 404, 'The network is exception.;网络异常')
            if (EdmUpObj[ID].fileCount === 1) {
              if (EdmUpObj[ID].replayFlag) {
                EdmUpObj[ID].failFunction(EdmUpObj[ID].uploadErrorCodes.NET_EXCEPTION)
                EdmUpObj[ID].replayFlag = false
              }            
              return false
            }
          } else if (EdmUpObj[ID].cacheDocuments[cacheSign].status.statusCode !== 407) {
            EdmUpUtils.updateDocStatus(ID, cacheSign, 407, '未知错误,上传失败')
            EdmUpObj[ID].fileResponseErrorHandler(atom, EdmUpObj[ID].uploadErrorCodes.UNKNOWN_ERROR, true, cacheSign)
          }
          EdmUpObj[ID].donePart(ID)
        }
        return false
      } else if (uploadXhr.status === 401) {
        EdmUpObj[ID].responseErrorResolve(atom, uploadXhr, function () {
          return EdmUpObj[ID].segmentUpload(batchIndex, atom, cacheSign)
        })
        return false
      }
    }
  },
  // 大文件分片上传请求
  setSegmentUploadXhh: function (ID) {
    EdmUpObj[ID].SegmentUploadXhh = function (ID, batchIndex, atom, slicingCheckCode, cacheSign, cnt = 0) {
      var segmentUploadData = EdmUpUtils.getSegmentUploadData(ID, atom, slicingCheckCode)
      EdmUpObj[ID].cacheDocuments[cacheSign].xhrMaps[atom.chunk] = EdmUpUtils.newXMLHttpRequest(
        EdmUpObj[ID].withCredentials
      )
      var uploadXhr = EdmUpObj[ID].cacheDocuments[cacheSign].xhrMaps[atom.chunk]
      var url = EdmUpObj[ID].edmServiceDomain + EdmUpObj[ID].contextPath.chunkUpload
      uploadXhr.open('POST', url, true)
      uploadXhr.setRequestHeader(EdmUpUtils.HEADERS.APP_ID, EdmUpObj[ID].appId)
      uploadXhr.setRequestHeader(EdmUpUtils.HEADERS.TRACE_ID, EdmUpObj[ID].requestTrace)
      uploadXhr.setRequestHeader(EdmUpUtils.HEADERS.TOKEN, EdmUpObj[ID].enterpriseDocumentToken)
      uploadXhr.setRequestHeader('x-csrf-token', EdmUpObj[ID].xCsrfToken)
      if (EdmUpObj[ID].cacheDocuments[cacheSign].abort) {
        EdmUpUtils.updateDocStatus(ID, cacheSign, 0, 'The upload is canceled,上传被取消')
        return
      }
      EdmUpUtils.updateDocStatus(ID, cacheSign, 105, 'Uploading Segments.;分片上传中')
      uploadXhr.onreadystatechange = function () {
        EdmUpUtils.handingUploadXhr(uploadXhr, atom, batchIndex, ID, slicingCheckCode, cacheSign, cnt)
      }
      var NetworkFlag = true
      var NetworkSpeed = setInterval(() => {
        NetworkFlag = true
      }, 1000)
      uploadXhr.upload.addEventListener(
        'progress',
        function (e) {
          if (NetworkFlag === true) {
            EdmUpUtils.CalculateNetworkSpeed(ID, e, atom)
            NetworkFlag = false
          }
          if (e.loaded === e.total) {
            clearInterval(NetworkSpeed)
          }
        },
        false
      )
      uploadXhr.send(segmentUploadData)
    }
  },
  setSegmentUpload: function (ID) {
    // 分片后的单片上传动作
    EdmUpObj[ID].segmentUpload = function (batchIndex, atom, cacheSign) {
      if (EdmUpObj[ID].isCheckCode === 'Y') {
        EdmUpUtils.updateDocStatus(ID, cacheSign, 103, 'Document is calculating encryption;文档正在计算加密中')
        var readerCode = new FileReader()
        readerCode.readAsArrayBuffer(atom)
        readerCode.onload = function (e) {
          var wordArray = CryptoJS.lib.WordArray.create(readerCode.result)
          var slicingCheckCode = CryptoJS.SHA256(wordArray).toString()
          EdmUpObj[ID].SegmentUploadXhh(ID, batchIndex, atom, slicingCheckCode, cacheSign)
        }
        readerCode.onerror = function (e) {
          EdmUpUtils.updateDocStatus(ID, cacheSign, 407, '未知错误,上传失败')
          EdmUpObj[ID].fileCount === 1
            ? EdmUpObj[ID].failFunction(EdmUpObj[ID].uploadErrorCodes.UNKNOWN_ERROR)
            : EdmUpObj[ID].donePart(ID)
        }
      } else {
        EdmUpObj[ID].SegmentUploadXhh(ID, batchIndex, atom, null, cacheSign)
      }
    }
  },
  // 对文件进行分片
  setSlicing: function (ID) {
    EdmUpObj[ID].slicing = function (f) {
      var chunkSize = f.chunkSize
      var chunkBatchArray = [[]]
      EdmUpObj[ID].batchQueue[f.docId + '-0'] = 0
      EdmUpObj[ID].batchQueueListen[f.docId + '-0'] = 0
      var chunkAtomSize = EdmUpUtils.getChunkAtomSize(ID)
      for (var i = 0; i < chunkSize; i++) {
        // 已上传过的分片就跳过
        if (f.records.indexOf((i + 1) + '') >= 0) {
          continue
        }
        var start = i * chunkAtomSize
        var end = Math.min(f.size, start + chunkAtomSize)
        var atom = f.slice(start, end)
        atom.chunk = i
        if (EdmUpObj[ID].isCheckCode === 'Y') {
          atom.checkCode = f.checkCode
        }
        atom.docId = f.docId
        atom.chunkSize = chunkSize
        atom.cacheSign = f.cacheSign
        var lastIndex = chunkBatchArray.length - 1
        if (chunkBatchArray[lastIndex].length < EdmUpUtils.getChunkGroupSize(ID)) {
          chunkBatchArray[lastIndex].push(atom)
        } else {
          chunkBatchArray.push([])
          EdmUpObj[ID].batchQueue[f.docId + '-' + (chunkBatchArray.length - 1)] = 0
          EdmUpObj[ID].batchQueueListen[f.docId + '-' + (chunkBatchArray.length - 1)] = 0
          EdmUpUtils.guid()
          chunkBatchArray[lastIndex + 1].push(atom)
        }
      }
      return chunkBatchArray
    }
  },
  setBatchSegmentUpload: function (ID) {
    // 分批次请求上传 文档ID-docId 当前操作的批次号-batchIndex 上传的所有批次-batchs
    EdmUpObj[ID].batchSegmentUpload = function (docId, batchIndex, batchs, cacheSign, file) {
      if (batchs[0].length !== 0 && batchIndex < batchs.length) {
        var key = docId + '-' + batchIndex
        var batch = batchs[batchIndex]
        // 添加当前批次的进度监听
        Object.defineProperty(EdmUpObj[ID].batchQueue, key, {
          get: function () {
            return EdmUpObj[ID].batchQueueListen[key]
          },
          set: function (value) {
            EdmUpObj[ID].batchQueueListen[key] = value
            if (value >= batch.length) {
              batchIndex++
              EdmUpObj[ID].batchSegmentUpload(docId, batchIndex, batchs, cacheSign, file)
            }
          }
        })
        var index = -1
        while (batch.length - index > 1) {
          index++
          EdmUpObj[ID].asyncFunction(EdmUpObj[ID].segmentUpload(batchIndex, batch[index], cacheSign))
          // 单片上传
        }
      } else if (file.resumableUpload && batchs[0].length === 0) {
        EdmUpObj[ID].mergeSegment(docId, ID)
      }
    }
  },
  // 开始分片
  SetStartSlicing: function (ID) {
    EdmUpObj[ID].StartSlicing = function (ID, f) {
      // 分片大小
      var chunkAtomSize = EdmUpUtils.CHUNK_ATOM * 1024 * 1024
      // 分片后的总片数
      var chunkSize = Math.ceil(f.size / chunkAtomSize)
      if (chunkSize - 10000 > 0) {
        chunkSize = 10000
      }
      f.chunkSize = chunkSize
      EdmUpObj[ID].segmentUploadInit(f, 0, false).then((initData)=> {
        EdmUpObj[ID].progressAutoIncrement(f.cacheSign, ID)
        if (initData === EdmUpUtils.EMPTY) {
          // ...
        } else {
          EdmUpUtils.updateDocStatus(ID, f.cacheSign, 105, 'The document is being fragmented.;文档正在分片')
          EdmUpUtils.setChunkConfig(ID, initData)
          // 开始分片
          f.docId = initData.docId
          f.records = initData.chunks
          f.progress = initData.chunks.length + 1
          if (Object.prototype.hasOwnProperty.call(initData, 'uploadChunkNum')) {
            f.chunkSize = initData.uploadChunkNum
          }
          var batchs = EdmUpObj[ID].slicing(f)
          EdmUpObj[ID].cacheDocuments[f.cacheSign].xhrMaps = {}
          EdmUpObj[ID].batchSegmentUpload(initData.docId, 0, batchs, f.cacheSign, f)
        }
      })
    }
  },
  incrementalCalcHashCode(algoMap, f, ID) {
    return new Promise((resolve, reject)=> {
      const reader = new FileReader()
      const chunkSize = EdmUpUtils.CHUNK_ATOM * 1024 * 1024
      const chunks = Math.ceil(f.size / chunkSize)
      const hashObj = {}
      let currentChunk = 0
      let hasher, md5Instance
      if (algoMap.SHA256) {
        hasher = CryptoJS.algo.SHA256.create()
      }
      if (algoMap.MD5) {
        md5Instance = md5.create()
      }
      const start = currentChunk * chunkSize
      const end = start + chunkSize >= f.size ? f.size : start + chunkSize
      reader.readAsArrayBuffer(File.prototype.slice.call(f, start, end))

      reader.onload = function (e) {
        var fileStr = e.target.result
        if (algoMap.SHA256) {
          var tmpWordArray = CryptoJS.lib.WordArray.create(fileStr)
          hasher.update(tmpWordArray)
        }
        if (algoMap.MD5) {
          md5Instance.update(fileStr)
        }
        currentChunk += 1
        fileStr = null
        tmpWordArray = null
        if (currentChunk < chunks) {
          EdmUpObj[ID].ComputeProgress(((currentChunk / chunks) * 100).toFixed(2))
          var start = currentChunk * chunkSize
          var end = start + chunkSize >= f.size ? f.size : start + chunkSize
          reader.readAsArrayBuffer(File.prototype.slice.call(f, start, end))
        } else {
          EdmUpObj[ID].ComputeProgress(100)
          if (algoMap.SHA256) {
            hashObj.SHA256 = hasher.finalize().toString()
          }
          if (algoMap.MD5) {
            hashObj.MD5 = md5Instance.hex()
          }
          resolve(hashObj)
        }
      }
      reader.onerror = function (e) {
        reject(e)
      }
    })
  },
  // 大文件上传准备
  setLargeDocumentUpload: function (ID) {
    // 大文件上传 f大文件
    EdmUpObj[ID].largeDocumentUpload = function (f, ID) {
      if ((EdmUpObj[ID].isEntireCheckCode !== 'N' && EdmUpObj[ID].isCheckCode === 'Y') || EdmUpObj[ID].duplicateCheckType === "SHA256" || EdmUpObj[ID].duplicateCheckType === "MD5") {
        EdmUpUtils.updateDocStatus(ID, f.cacheSign, 103, 'Document is calculating encryption;文档正在计算加密中')
        const algoMap = {
          SHA256: (EdmUpObj[ID].isEntireCheckCode !== 'N' && EdmUpObj[ID].isCheckCode === 'Y') || EdmUpObj[ID].duplicateCheckType === "SHA256",
          MD5: EdmUpObj[ID].duplicateCheckType === "MD5"
        }
        EdmUpUtils.incrementalCalcHashCode(algoMap, f, ID).then((hashObj)=> {
          if (EdmUpObj[ID].isEntireCheckCode !== 'N' && EdmUpObj[ID].isCheckCode === 'Y') {
            f.checkCode = hashObj.SHA256
          }
          if (EdmUpObj[ID].duplicateCheckType === "SHA256") {
            f.duplicateCheckCode = hashObj.SHA256
          } else if (EdmUpObj[ID].duplicateCheckType === "MD5") {
            f.duplicateCheckCode = hashObj.MD5
          }
          EdmUpObj[ID].StartSlicing(ID, f)
        }).catch((error)=> {
          EdmUpUtils.updateDocStatus(ID, f.cacheSign, 407, '未知错误,上传失败')
          EdmUpObj[ID].fileCount === 1
            ? EdmUpObj[ID].failFunction(EdmUpObj[ID].uploadErrorCodes.UNKNOWN_ERROR)
            : EdmUpObj[ID].donePart(ID)
        })
      } else {
        EdmUpObj[ID].StartSlicing(ID, f)
      }
    }
  },
  // 对文件上传大小进行校验
  ifVerifyFailMaxSizeList: function (verifyFailMaxSizeList, verifyFailAllowedList, ID) {
    if (verifyFailMaxSizeList.length > 0) {
      const status = EdmUpObj[ID].uploadErrorCodes.MAX_SIZE.status
      const message = EdmUpObj[ID].uploadErrorCodes.MAX_SIZE.message
                        + convertUnit(EdmUpObj[ID].tenantUploadConfig.singleFileMaxSize)
      const CustomizedErrorCodes = customizedError(status, message)
      CustomizedErrorCodes.data = verifyFailMaxSizeList
      EdmUpObj[ID].failFunction(CustomizedErrorCodes)
    } else if (verifyFailAllowedList.length > 0) {
      const status = EdmUpObj[ID].uploadErrorCodes.FILENAME_IS_NOTIN_WHITELIST.status
      const message = EdmUpObj[ID].uploadErrorCodes.FILENAME_IS_NOTIN_WHITELIST.message
      var error = { status: status, message: message }
      let fileArray = []
      for (let i = 0; i < verifyFailAllowedList.length; i ++) {
        fileArray.push(verifyFailAllowedList[i].name.substring(verifyFailAllowedList[i].name.lastIndexOf('.')))
      }
      fileArray = [...new Set(fileArray)]
      let tempStr = EdmUpObj[ID].uploadErrorCodes.FILENAME_IS_NOTIN_WHITELIST.message
      error.message = tempStr.replace('.suffix', fileArray.join(','))
      error.data = verifyFailAllowedList
      EdmUpObj[ID].failFunction(error)
    }
  },
  docUploadVerify: function (ID, files) {
    var isOK = true
    var okFlag = false
    var maxSize = EdmUpObj[ID].tenantUploadConfig.singleFileMaxSize
    var suffixList = []
    if (EdmUpObj[ID].whitelist !== '') {
      suffixList = EdmUpObj[ID].whitelist.split(',')
    }
    var verifyFailMaxSizeList = []
    var verifyFailAllowedList = []
    for (var i = 0; i < files.length; i++) {
      var file = files[i]
      if (file.size > EdmUpObj[ID].tenantUploadConfig.singleFileMaxSize) {
        verifyFailMaxSizeList.push(file)
        isOK = false
      }
      if (EdmUpObj[ID].fileSizeRange) {
        if (file.size > EdmUpObj[ID].fileSizeRange[1]) {
          isOK = false
          okFlag = true
          const status = EdmUpObj[ID].uploadErrorCodes.MAX_SIZE.status
          const message = EdmUpObj[ID].uploadErrorCodes.MAX_SIZE.message
                            + convertUnit(EdmUpObj[ID].fileSizeRange[1])
          const error = customizedError(status, message)
          error.data = verifyFailMaxSizeList
          EdmUpObj[ID].failFunction(error)
          return false
        } else if (file.size < EdmUpObj[ID].fileSizeRange[0]) {
          isOK = false
          okFlag = true
          const status = EdmUpObj[ID].uploadErrorCodes.MIN_SIZE.status
          const message = EdmUpObj[ID].uploadErrorCodes.MIN_SIZE.message
                            + convertUnit(EdmUpObj[ID].fileSizeRange[0])
          const error = customizedError(status, message)
          error.data = verifyFailMaxSizeList
          EdmUpObj[ID].failFunction(error)
          return false
        } else {
          isOK = true
        }
      }
      var fileSuffix = ''
      let isNoSuffixFile = false
      if (file.name.indexOf('.') < 0) {
        isNoSuffixFile = true
        if (suffixList.length > 0 && suffixList.indexOf('.null') < 0 && suffixList.indexOf('.*') < 0) {
          EdmUpObj[ID].failFunction(EdmUpObj[ID].uploadErrorCodes.NO_SUFFIX_FILE)
          isOK = false
        }
      } else {
        fileSuffix = file.name.substring(file.name.lastIndexOf('.')).toLocaleLowerCase()
      }
      if (!isNoSuffixFile && suffixList.indexOf(fileSuffix) < 0 && suffixList.indexOf('.*') < 0 && suffixList.length > 0) {
        verifyFailAllowedList.push(file)
        isOK = false
      }
    }
    EdmUpUtils.ifVerifyFailMaxSizeList(verifyFailMaxSizeList, verifyFailAllowedList, ID)
    if (okFlag) {
      isOK = false
    }
    return isOK
  },
  // 文档校验
  setVerify: function (ID) {
    EdmUpObj[ID].verify = function (files) {
      if (EdmUpObj[ID].single && files.length > 1) {
        EdmUpObj[ID].failFunction(EdmUpObj[ID].uploadErrorCodes.REPEATED_UPLOAD)
        return false
      }
      if (files.length > EdmUpObj[ID].tenantUploadConfig.uploadMaxQty) {
        const status = EdmUpObj[ID].uploadErrorCodes.EXCEEDS_LIMIT.status
        const message = EdmUpObj[ID].uploadErrorCodes.EXCEEDS_LIMIT.message 
                          + EdmUpObj[ID].tenantUploadConfig.uploadMaxQty 
                          + `${EdmUpObj[ID].edmLanguage === 'zh_CN' ? '个' : ''}`
        const CustomizedErrorCodes = customizedError(status, message)
        
        EdmUpObj[ID].failFunction(CustomizedErrorCodes)
        return false
      }
      return EdmUpUtils.docUploadVerify(ID, files)
    }
  },
  // 进行上传初始化
  setInvokeUpload: function (ID) {
    // 执行上传 文件列表 files
    EdmUpObj[ID].invokeUpload = function (files) {
      for(let i = 0; i < files.length; i ++) {
        if (localStorage.getItem(files[i].name + '#' + files[i].size + '#' + files[i].lastModified)) {
          files[i].docId = localStorage.getItem(files[i].name + '#' + files[i].size + '#' + files[i].lastModified)
          files[i].resumableUpload = true
        }
      }
      if (EdmUpObj[ID].parallelUpload) {
        EdmUpObj[ID].files = files
        if (files.length > EdmUpObj[ID].tenantUploadConfig.uploadMaxQty) {
          const status = EdmUpObj[ID].uploadErrorCodes.EXCEEDS_LIMIT.status
          const message = EdmUpObj[ID].uploadErrorCodes.EXCEEDS_LIMIT.message
                            + EdmUpObj[ID].tenantUploadConfig.uploadMaxQty
                            + `${EdmUpObj[ID].edmLanguage === 'zh_CN' ? '个' : ''}`
          const CustomizedErrorCodes = customizedError(status, message)
          EdmUpObj[ID].failFunction(CustomizedErrorCodes)
          return false
        }
        let curDocuments = []
        for(let i = 0; i < files.length; i ++) {
          if (EdmUpUtils.parallelVerficate(ID, files[i])) {
            curDocuments.push(files[i])
          }
        }
        if (curDocuments.length === 0) {
          EdmUpUtils.paralUploadFail(ID, files, curDocuments)
        }
        EdmUpObj[ID].curDocuments = curDocuments
        EdmUpObj[ID].fileCount = curDocuments.length
        EdmUpUtils.filesInit(ID, curDocuments)
        for (let i = 0; i < curDocuments.length; i ++) {
          if (EdmUpObj[ID].chunkAtom && curDocuments[i].size > EdmUpUtils.CHUNK_ATOM * 1024 * 1024) {
            new Promise((resolve, reject) => {
              EdmUpObj[ID].largeDocumentUpload(curDocuments[i], ID)
            })
          } else if (!EdmUpObj[ID].chunkAtom && curDocuments[i].size > EdmUpUtils.CHUNK_LIMIT * 1024 * 1024) {
            new Promise((resolve, reject) => {
              EdmUpObj[ID].largeDocumentUpload(curDocuments[i], ID)
            })
          } else {
            new Promise((resolve, reject) => {
              EdmUpObj[ID].smallDocumentUpload(curDocuments[i], ID)
            })
          }
        }
      } else {
        if (EdmUpObj[ID].verify(files)) {
          // 每隔4分钟获取edmtoken
          EdmUpUtils.filesInit(ID, files)
          EdmUpUtils.uploadPortal(ID, true, files)
        }
      }
    }
  },
  setPortal: function (ID) {
    // file元素change事件 {事件点} e
    EdmUpObj[ID].portal = function (e) {
      var files = e.target.files
      var src = window.URL.createObjectURL(files[0])
      if (e.target.remove) {
        e.target.remove()
      } else {
        e.target.removeNode()
      }
      EdmUpObj[ID].uploading = true
      EdmUpObj[ID].invokeUpload(files)
    }
  },
  // 判断浏览器类型获取上传节点信息
  getPath: function () {
    var PathObj = document.getElementById('uploadinput')
    if (PathObj) {
      if (window.navigator.userAgent.indexOf('MSIE') >= 1) {
        PathObj.select()
        return document.selection.createRange().text
      } else if (window.navigator.userAgent.indexOf('Firefox') >= 1) {
        if (PathObj.files) {
          return PathObj.files.item(0).getAsDataURL()
        }
        return PathObj.value
      }
      return PathObj.value
    }
  },

  /**
   * 获取分片大小
   * @param {string} id 上传实例ID
   * @returns {number} 分片大小
   */
  getChunkAtomSize(id) {
    let config = EdmUpObj[id].uploadChunkConfig || {}

    if (Object.prototype.hasOwnProperty.call(config, 'uploadChunkSize')) {
      return config.uploadChunkSize * 1024 * 1024
    }

    return EdmUpUtils.CHUNK_ATOM * 1024 * 1024
  },

  /**
   * 获取并发数
   * @param {string} id 上传实例ID
   * @returns {number} 并发数
   */ 
  getChunkGroupSize(id) {
    let config = EdmUpObj[id].uploadChunkConfig || {}

    if (Object.prototype.hasOwnProperty.call(config, 'uploadChunkGroupSize')) {
      return config.uploadChunkGroupSize
    }

    return EdmUpUtils.CHUNK_BATCH_LIMIT
  },

  /**
   * 设置分片配置信息
   * @param {string} id 上传实例ID
   * @param {object} data 响应返回的分片信息
   */ 
  setChunkConfig(id, data) {
    let { uploadChunkSize, uploadChunkGroupSize } = data
    
    EdmUpObj[id].uploadChunkConfig = {}

    if (EdmUpUtils.isNumeric(uploadChunkSize)) {
      EdmUpObj[id].uploadChunkConfig.uploadChunkSize = uploadChunkSize
    }
    
    if (EdmUpUtils.isNumeric(uploadChunkGroupSize)) {
      EdmUpObj[id].uploadChunkConfig.uploadChunkGroupSize = uploadChunkGroupSize
    }
  },

  /**
   * 串行上传取消指定文件后更新上传状态，继续后面文件上传
   * @param {string} id 上传实例ID
   */
  setAbortProgress(id) {
    EdmUpObj[id].updateAbortProgress = function (index) {
      if (!EdmUpObj[id].parallelUpload) {
        if (index === EdmUpObj[id].uploadIndex) { // 取消当前上传文档则开始下一个
          EdmUpObj[id].donePart(id)
        } else { // 取消非当前上传文档只更新进度状态
          EdmUpObj[id].uploadHandleProgress(EdmUpObj[id].refreshProgress())
        }
      }
    }
  },

  /**
   * 判断是否为大文件
   * @param {string} id 上传实例ID
   * @param {File} file 上传文件
   */
  isBigFile(id, file) {
    return ((!EdmUpObj[id].chunkAtom && file.size > EdmUpUtils.CHUNK_LIMIT * 1024 * 1024) ||
        (EdmUpObj[id].chunkAtom && file.size > EdmUpUtils.CHUNK_ATOM * 1024 * 1024));
  }
}

var EDMUploadInstance = function (options) {
  var ID = EdmUpUtils.getUpRandomValue()
  EdmUpUtils.pageRefreshListen(ID)
  EdmUpObj[ID] = options
  EdmUpUtils.setDefaultLanguage(ID)
  if (EdmUpUtils.isEmptyOp(ID) || EdmUpUtils.isEmptyAppId(ID)) {
    return
  }
  EdmUpUtils.defaultNetwork(ID)
  EdmUpUtils.setFail(ID)
  EdmUpUtils.setNotice(ID)
  EdmUpUtils.setNetworkSpeed(ID)
  EdmUpUtils.setStatusCallback(ID)
  EdmUpUtils.setHandleProgress(ID)
  EdmUpUtils.initConfiguration(ID)
  EdmUpUtils.setServiceDomain(ID)
  EdmUpUtils.setAsyncFunction(ID)
  EdmUpUtils.setRequestEdmToken(ID)
  EdmUpUtils.setDonePart(ID)
  EdmUpUtils.setRefreshProgress(ID)
  EdmUpUtils.setUploadPortal()
  EdmUpUtils.setCreateTempInputFile(ID)
  EdmUpUtils.setFileResponseErrorHandler(ID)
  EdmUpUtils.setResponseErrorResolve(ID)
  EdmUpUtils.setProgressAutoIncrement(ID)
  EdmUpUtils.setMergeSegment(ID)
  EdmUpUtils.setSmallDocumentUpload(ID)
  EdmUpUtils.setSmallDocumentUploadXhh(ID)
  EdmUpUtils.setSegmentUploadInit(ID)
  EdmUpUtils.setSegmentUpload(ID)
  EdmUpUtils.setSegmentUploadXhh(ID)
  EdmUpUtils.setSlicing(ID)
  EdmUpUtils.SetStartSlicing(ID)
  EdmUpUtils.setBatchSegmentUpload(ID)
  EdmUpUtils.setLargeDocumentUpload(ID)
  EdmUpUtils.setVerify(ID)
  EdmUpUtils.setInvokeUpload(ID)
  EdmUpUtils.setPortal(ID)
  EdmUpUtils.setComputeProgress(ID)
  EdmUpUtils.setAbortProgress(ID)

  // 文件上传反方，如果文件为空则创建输入框让用户上传文件，否则对文件进行更新
  this.upload = (files) => {
    EdmUpObj[ID].replayFlag = true
    if (EdmUpObj[ID].uploading) {
      EdmUpObj[ID].failFunction(EdmUpObj[ID].uploadErrorCodes.EXECUTION_INSTANCE)
    } else {
      EdmUpUtils.initConfiguration(ID)
      EdmUpObj[ID].requestEdmToken(true).then((tokenFlag) => {
        if (tokenFlag) {
          if (EdmUpUtils.isEmpty(files)) {
            EdmUpObj[ID].createTempInputFile(EdmUpObj[ID].portal)
            EdmUpObj[ID].tempInputFileDocument.click()
          } else {
            EdmUpObj[ID].uploading = true
            EdmUpObj[ID].invokeUpload(files)
          }
        }
      })
    }
  }
  this.abortUpload = (index) => {
    if (EdmUpObj[ID].cacheDocumentKeys.length > 0) {
      let key = EdmUpObj[ID].cacheDocumentKeys[index]
      if (key) {
        const file = EdmUpObj[ID].cacheDocuments[key]
        if (file.abort || (file.status && file.status.statusCode === 200)) {
          return
        }
        EdmUpUtils.updateDocStatus(ID, key, 0, 'The upload is canceled,上传被取消')
        file.abort = true
        const uploadingKey = `${file.name}#${file.size}#${file.lastModified}#uploading`
        localStorage.removeItem(uploadingKey)
        if (EdmUpUtils.isBigFile(ID, file)) {
          for (const k in file.xhrMaps) {
            if (Object.hasOwnProperty.call(file.xhrMaps, k)) {
              const element = file.xhrMaps[k]
              element.abort()
            }
          }
        } else {
          if (file.xhr) {
            EdmUpUtils.updateDocStatus(ID, key, 0, 'The upload is canceled,上传被取消')
            file.xhr.abort()
          }
        }
        EdmUpObj[ID].updateAbortProgress(index)
      }
    }
  }
  this.setEdmUploadLanguage = (lang) => {
    setErrorCodesLanguage(ID, lang)
  }
}
window.EDMUploadInstance = EDMUploadInstance
